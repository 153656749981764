import { bool, func } from 'prop-types';
import React from 'react';
import { SEARCH_OVERLAY } from '../../constants';
import { refType } from '../../constants/types';

import styles from './searchButton.css';
import OverlayToggleButton from '../OverlayToggleButton';
import useTranslation from '../../hooks/useTranslation';

const SearchButton = ({
  toggleMenu, isFocus = false, searchReturnRef, showLabel
}) => {
  const { t } = useTranslation();

  return (
    <OverlayToggleButton
      returnRef={searchReturnRef}
      label={t('search.iconLabel')}
      ariaLabel={t('search.iconLabel')}
      showLabel={showLabel}
      toggleMenu={toggleMenu}
      isFocus={isFocus}
      overlayName={SEARCH_OVERLAY}
      isMobileMenuOpen={false}
    >
      <span className={styles.icon} />
    </OverlayToggleButton>
  );
};

SearchButton.propTypes = {
  isFocus: bool,
  searchReturnRef: refType,
  toggleMenu: func,
  showLabel: bool
};

export default SearchButton;
