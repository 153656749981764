import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { getAnnouncements } from '../../api';
import { announcementTypes } from '../../constants/types';
import AnnouncementItem from './AnnouncementItem';
import styles from './announcements.css';
import { getRootAttribute } from '../../config/dom-config';

const COOKIE_NAME = 'HIDDEN_ANNOUNCEMENTS';
const COOKIE_SETTINGS = {
  expires: 365
};

const getHiddenAnnouncements = () => {
  const hidden = Cookies.get(COOKIE_NAME);
  if (hidden) {
    try {
      return JSON.parse(hidden);
    } catch {
      return [];
    }
  }
  return [];
};

const hideAnnouncement = (announcement) => {
  const hidden = getHiddenAnnouncements();
  if (hidden.includes(announcement.id) || announcement.type === announcementTypes.CRITICAL) {
    return false;
  }

  hidden.push(announcement.id);
  Cookies.set(COOKIE_NAME, JSON.stringify(hidden), COOKIE_SETTINGS);
  return true;
};

const cleanHiddenAnnouncements = (announcements) => {
  const hidden = getHiddenAnnouncements();
  const existingHidden = announcements
    .filter(announcement => hidden.includes(announcement.id))
    .map(announcement => announcement.id);

  Cookies.set(COOKIE_NAME, existingHidden, COOKIE_SETTINGS);
};

const Announcements = ({ langCode }) => {
  const [announcements, setAnnouncements] = useState([]);

  useEffect(() => {
    (async () => {
      const announcementSources = getRootAttribute('data-announcement-sources') || undefined;
      const result = await getAnnouncements(announcementSources, langCode) || [];
      const hidden = getHiddenAnnouncements();
      const visible = result.filter(a => !hidden.includes(a.id));
      setAnnouncements(visible);
      cleanHiddenAnnouncements(result);
    })();
  }, [langCode]);

  const onHideAnnouncement = (announcement) => {
    if (hideAnnouncement(announcement)) {
      setAnnouncements(announcements.filter(a => a.id !== announcement.id));
    }
  };

  return announcements.length ? (
    <div role="alert" className={styles.announcementsContainer}>
      {announcements.map((announcement => (
        <AnnouncementItem
          key={announcement.id}
          announcement={announcement}
          hideAnnouncement={onHideAnnouncement}
        />
      )))}
    </div>
  ) : null;
};

Announcements.propTypes = {
  langCode: PropTypes.string
};

export default Announcements;
