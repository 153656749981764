import React from 'react';
import { string } from 'prop-types';

import { trackLogin, trackLogout } from '../../tracking';
import styles from './authControls.css';
import { userType } from '../../constants/types';
import useTranslation from '../../hooks/useTranslation';

const AuthControls = ({ user, loginEndpoint, logoutEndpoint }) => {
  const { t } = useTranslation();

  const isLoggedIn = user;
  const linkEndpoint = isLoggedIn ? logoutEndpoint : loginEndpoint;
  const onClick = () => (isLoggedIn ? trackLogout() : trackLogin());

  return (
    <div className={styles.authControlContainer}>
      <a href={linkEndpoint} target="_self" onClick={onClick}>
        {t(`authControls.${isLoggedIn ? 'logOut' : 'logIn'}`)}
      </a>
    </div>
  );
};

AuthControls.propTypes = {
  user: userType,
  loginEndpoint: string,
  logoutEndpoint: string
};

export default AuthControls;
