import React from 'react';

export const THEMES = {
  LIGHT: 'light',
  DARK: 'dark'
};

const ThemeContext = React.createContext({ theme: THEMES.LIGHT, isSamsungBrowser: false });

export default ThemeContext;
