import React from 'react';
import { func } from 'prop-types';
import { format, parseISO, isValid } from 'date-fns';

import { DATE_TIME_FORMAT } from '../../constants';
import {
  announcementItemType,
  announcementTypes
} from '../../constants/types';
import { getTranslation } from '../../util';
import styles from './announcements.css';
import ExternalLink from '../ExternalLink';
import SanitizedHtml from '../SanitizedHtml';
import useTranslation from '../../hooks/useTranslation';

const AnnouncementItem = ({
  announcement,
  hideAnnouncement
}) => {
  const { t, lang } = useTranslation();
  const onClickHideAnnouncement = () => hideAnnouncement(announcement);

  const getAnnouncementStyle = () => {
    switch (announcement.crisisType) {
      case announcementTypes.REGULAR:
        return styles.regularItem;
      case announcementTypes.CRITICAL:
      default:
        return styles.crisisItem;
    }
  };

  const AnnouncementIcon = () => {
    switch (announcement.crisisType) {
      case announcementTypes.REGULAR:
        return <span className={`${styles.announcementIcon} icon--info-stroke`} />;
      case announcementTypes.CRITICAL:
      default:
        return <span className={`${styles.announcementIcon} icon--warning`} />;
    }
  };

  const AnnouncementLink = () => {
    const { link, linkDescription } = announcement;
    const translatedLink = getTranslation(link, lang);
    const translatedDescription = getTranslation(linkDescription, lang);

    if (!translatedLink) {
      return null;
    }

    const urlObject = new URL(translatedLink);
    const linkIsInternal = window.location.hostname === urlObject.hostname;

    return (
      <ExternalLink
        className={styles.announcementLink}
        href={translatedLink}
        iconColor={announcement.crisisType === announcementTypes.REGULAR ? 'black' : 'white'}
        noIcon={linkIsInternal}
      >
        {translatedDescription || translatedLink}
      </ExternalLink>
    );
  };

  const AnnouncementDate = () => {
    const date = announcement.modifiedDate && parseISO(announcement.modifiedDate);
    return isValid(date) ? (
      <span className={styles.announcementDate}>{format(date, DATE_TIME_FORMAT[lang])}</span>
    ) : null;
  };

  const AnnouncementInfoContent = () => {
    if (!announcement.content) {
      return null;
    }

    return (
      <div className={styles.announcementInfoContent}>
        <SanitizedHtml html={getTranslation(announcement.content, lang)} />
        {' '}
        <AnnouncementLink />
        {announcement.modifiedDate && (
          <AnnouncementDate />
        )}
      </div>
    );
  };

  return (
    <div className={`${styles.announcementItem} ${getAnnouncementStyle()}`}>
      <div className={styles.announcementItemContent}>
        <div className={styles.announcementIconContainer}>
          <div className={styles.announcementIcon}>
            <AnnouncementIcon />
          </div>
        </div>

        <div className={styles.announcementInfoContainer}>
          <h4>{getTranslation(announcement.title, lang)}</h4>
          <AnnouncementInfoContent />
        </div>

        {announcement.crisisType === announcementTypes.REGULAR && (
          <div className={styles.hideAnnouncementContainer}>
            <button
              type="button"
              className="button button--icon"
              onClick={onClickHideAnnouncement}
            >
              <span className="sr-only">{t('hide')}</span>
              <span className="icon--remove" aria-hidden="true" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

AnnouncementItem.propTypes = {
  announcement: announcementItemType.isRequired,
  hideAnnouncement: func.isRequired
};

export default AnnouncementItem;
