import React, { useRef, useEffect } from 'react';
import { string, bool } from 'prop-types';
import { hyphenateText } from '../../../util';

import styles from './quickLink.css';
import { trackServicesAndToolsClick } from '../../../tracking';
import ExternalLink from '../../ExternalLink';
import useTranslation from '../../../hooks/useTranslation';

const QuickLink = ({
  service, isFocus, isMobile, wide = false
}) => {
  const { t } = useTranslation();
  const anchorRef = useRef();

  const onClick = () => trackServicesAndToolsClick(service);

  useEffect(() => {
    if (isFocus) {
      anchorRef.current.focus();
    }
  }, [isFocus]);

  const serviceUrl = t(`links.${service}.url`);
  const isInternal = serviceUrl.includes('https://studies.helsinki.fi');

  const wrappedContent = (
    <>
      <div className={styles.serviceTitle}>
        {t(`links.${service}.name`)}
      </div>
      {!isMobile && (
        <span aria-label={t(`links.${service}.description`)} className={styles.itemDescription}>
          {hyphenateText(t(`links.${service}.description`))}
        </span>
      )}
    </>
  );

  return (
    <li className={`${styles.item} ${(!wide && styles.narrow) || ''}`}>
      {isInternal ? (
        <a href={serviceUrl} ref={anchorRef} onClick={onClick} onAuxClick={onClick}>
          {wrappedContent}
        </a>
      ) : (
        <ExternalLink className={styles.externalLink} href={serviceUrl} passedRef={anchorRef} onClick={onClick} onAuxClick={onClick} noIcon>
          {wrappedContent}
        </ExternalLink>
      )}
    </li>
  );
};

QuickLink.propTypes = {
  service: string.isRequired,
  isFocus: bool,
  isMobile: bool,
  wide: bool
};

export default QuickLink;
