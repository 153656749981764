import React from 'react';
import { func, bool } from 'prop-types';
import {
  appConfigsType,
  helkaCardButtonInfoType,
  notificationsInfoType,
  searchButtonInfoType,
  userType
} from '../../constants/types';
import MobileMenu from './MobileMenu';

import styles from './actions.css';
import { NotificationBadge } from '../NotificationsButton';
import { mergeRefs } from '../../util';
import SearchButton from '../SearchButton';
import useTranslation from '../../hooks/useTranslation';

const MobileActions = ({
  isMenuOpen,
  isQuickLinksOpen,
  handleMenuButtonClick,
  handleQuickLinksClick,
  appConfigs,
  notificationsInfo,
  helkaCardButtonInfo,
  searchButtonInfo,
  user
}) => {
  const { t } = useTranslation();

  const buttonIcon = isMenuOpen ? `icon--remove ${styles.closeButton}` : 'icon--hamburger';
  const buttonAriaLabel = isMenuOpen ? 'closeMenu' : 'openMenu';
  const { showNotifications, unreadNotificationCount, notificationsReturnRef } = notificationsInfo;
  const { searchReturnRef, showSearchButton } = searchButtonInfo;
  const showBadge = showNotifications && !isMenuOpen;
  const { helkaCardReturnRef } = helkaCardButtonInfo;

  const labelId = 'obar-menu-icon';
  const menuUnreadIndicatorId = 'obar-menu-unread-notifications-indicator';

  return (
    <div className={styles.mobileActions}>
      {showSearchButton && (
        <SearchButton searchReturnRef={searchReturnRef} showLabel={false} />
      )}
      <button
        type="button"
        className={styles.menuButton}
        onClick={handleMenuButtonClick}
        ref={mergeRefs(notificationsReturnRef, helkaCardReturnRef)}
        aria-expanded={isMenuOpen}
        aria-labelledby={[labelId, menuUnreadIndicatorId].join(' ')}
      >
        <span id={labelId} className={buttonIcon} aria-label={t(buttonAriaLabel)} />
        {showBadge && <NotificationBadge unreadNotificationCount={unreadNotificationCount} id={menuUnreadIndicatorId} />}
      </button>
      { isMenuOpen && (
        <MobileMenu
          appConfigs={appConfigs}
          notificationsInfo={notificationsInfo}
          isQuickLinksOpen={isQuickLinksOpen}
          handleQuickLinksClick={handleQuickLinksClick}
          toggleMenu={handleMenuButtonClick}
          user={user}
        />
      )}
    </div>
  );
};

MobileActions.propTypes = {
  isMenuOpen: bool.isRequired,
  isQuickLinksOpen: bool.isRequired,
  handleMenuButtonClick: func.isRequired,
  handleQuickLinksClick: func.isRequired,
  appConfigs: appConfigsType.isRequired,
  notificationsInfo: notificationsInfoType.isRequired,
  helkaCardButtonInfo: helkaCardButtonInfoType.isRequired,
  searchButtonInfo: searchButtonInfoType.isRequired,
  user: userType
};

export default MobileActions;
