import React from 'react';
import styles from './footer.css';
import { useDeviceSize } from '../../context/DeviceSizeContext/DeviceSizeContextProvider';
import { deviceSizes, FOOTER_LINKS } from '../../constants';
import MobileLayout from './MobileLayout';
import TabletLayout from './TabletLayout';
import DesktopLayout from './DesktopLayout';
import ScrollUpButton from './ScrollUpButton';

const Footer = () => {
  const { deviceSize } = useDeviceSize();

  return (
    <div className={styles.container}>
      <div className={`l-footer__subregion ${styles.columnContainer}`}>
        <div className={styles.scrollButtonContainer}>
          <ScrollUpButton />
        </div>
        {deviceSize === deviceSizes.DESKTOP && (
          <DesktopLayout items={FOOTER_LINKS} />
        )}
        {deviceSize === deviceSizes.LARGE_TABLET && (
          <TabletLayout items={FOOTER_LINKS} />
        )}
        {deviceSize === deviceSizes.SMALL_TABLET && (
          <MobileLayout items={FOOTER_LINKS} />
        )}
      </div>
    </div>
  );
};

export default Footer;
