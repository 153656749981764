import React from 'react';
import { func, string } from 'prop-types';

import { trackFooterLinkClick } from '../../tracking';
import ExternalLink from '../ExternalLink';
import Arrow from '../Arrow';
import styles from './footerLink.css';

const FooterLink = ({ translate, item }) => {
  const itemUrl = translate(`footer.links.${item}.url`);
  const itemName = translate(`footer.links.${item}.name`);
  const internal = itemUrl.includes('https://studies.helsinki.fi');
  const className = `list-of-links__link button--action theme-transparent-alt ${styles.link}`;

  return internal ? (
    <li>
      <a
        href={itemUrl}
        className={`${className} ${styles.internalLink}`}
        onClick={() => trackFooterLinkClick(item)}
        onAuxClick={() => trackFooterLinkClick(item)}
      >
        {itemName}
        <Arrow />
      </a>
    </li>
  ) : (
    <li>
      <ExternalLink
        href={itemUrl}
        className={`${className} ${styles.externalLink}`}
        iconClass={styles.iconClass}
        onClick={() => trackFooterLinkClick(item)}
        onAuxClick={() => trackFooterLinkClick(item)}
        arrowRight
      >
        {itemName}
      </ExternalLink>
    </li>
  );
};

FooterLink.propTypes = {
  translate: func.isRequired,
  item: string.isRequired
};

export default FooterLink;
