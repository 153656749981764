import React, { useState } from 'react';

import QuickLinksMenu from './QuickLinksMenu';
import styles from './quickLinks.css';
import { trackServicesAndToolsState } from '../../tracking';
import useTranslation from '../../hooks/useTranslation';

const QuickLinks = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const onClickOutsideMenu = () => {
    setOpen(false);
    trackServicesAndToolsState('close');
  };

  const toggleMenu = () => {
    if (!open) {
      trackServicesAndToolsState('open');
    } else {
      trackServicesAndToolsState('close');
    }
    setOpen(!open);
  };

  return (
    <>
      <button
        type="button"
        className={`${styles.linksButton} ignore-react-onclickoutside`}
        onClick={toggleMenu}
        aria-haspopup
        aria-expanded={open}
      >
        {t('quickLinks.nameHeading')}
        <span className="icon--caret-down" />
      </button>
      {open
        && <QuickLinksMenu onClickOutside={onClickOutsideMenu} /> }
    </>
  );
};

export default QuickLinks;
