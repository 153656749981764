import React, { useEffect, useRef } from 'react';
import {
  arrayOf, bool, func, node, oneOfType, string
} from 'prop-types';
import { useOverlay } from '../../context/OverlayContext';
import { mergeRefs } from '../../util';
import styles from './overlayToggleButton.css';
import { refType } from '../../constants/types';

const OverlayToggleButton = ({
  returnRef,
  toggleMenu,
  overlayName,
  isFocus,
  trackOnClick,
  label,
  showLabel = true,
  children,
  labelId,
  ariaLabelledBy,
  ariaLabel,
  isMobileMenuOpen
}) => {
  const { activeOverlay, setActiveOverlay } = useOverlay();
  const focusRef = useRef();

  useEffect(() => {
    if (isFocus && focusRef.current && isMobileMenuOpen) {
      focusRef.current.focus();
    }
  }, [isFocus, isMobileMenuOpen]);

  const handleOnClick = () => {
    if (trackOnClick) {
      trackOnClick();
    }
    if (toggleMenu) {
      toggleMenu();
    }

    if (activeOverlay === overlayName) {
      setActiveOverlay(null);
    } else {
      setActiveOverlay(overlayName);
    }
  };

  return (
    <button
      type="button"
      ref={mergeRefs(returnRef, focusRef)}
      className={styles.button}
      onClick={handleOnClick}
      aria-labelledby={ariaLabelledBy?.join(' ')}
      aria-label={ariaLabel}
      aria-haspopup="dialog"
    >
      {children}
      {showLabel && <span className={styles.label} id={labelId}>{label}</span>}
    </button>
  );
};

OverlayToggleButton.propTypes = {
  returnRef: refType,
  toggleMenu: func,
  overlayName: string,
  isFocus: bool,
  trackOnClick: func,
  label: string,
  showLabel: bool,
  children: oneOfType([node, arrayOf(node)]),
  ariaLabelledBy: arrayOf(string),
  ariaLabel: string,
  labelId: string,
  isMobileMenuOpen: bool
};

export default OverlayToggleButton;
