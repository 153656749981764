import React, { useContext, useEffect, useState } from 'react';
import { node } from 'prop-types';
import { DEFAULT_LANG } from '../../constants';
import LangContext from '../../context/LangContext';
import { configType } from '../../constants/types';

const InitializeLang = ({ children, config }) => {
  const { setLang } = useContext(LangContext);
  const { currentLang } = config;
  const [langInitialized, setLangInitialized] = useState(false);

  const defaultLanguage = currentLang || DEFAULT_LANG;

  useEffect(() => {
    if (!langInitialized) {
      setLang(defaultLanguage);
      setLangInitialized(true);
    }
  }, [langInitialized, defaultLanguage, setLang]);

  return (
    <>
      {langInitialized ? children : null}
    </>
  );
};

InitializeLang.propTypes = {
  children: node.isRequired,
  config: configType.isRequired
};

export default InitializeLang;
