import React from 'react';
import { arrayOf, string } from 'prop-types';
import FooterColumn from '../../FooterColumn';
import FooterLinks from '../../FooterLinks';
import ContactInfo from '../ContactInfo';
import ServiceName from '../ServiceName';

import styles from '../footer.css';
import useTranslation from '../../../hooks/useTranslation';

const MobileLayout = ({ items }) => {
  const { t } = useTranslation();
  return (
    <>
      <FooterColumn className={styles.mobileColumn}>
        <ServiceName />
      </FooterColumn>
      <FooterColumn className={styles.mobileColumn}>
        <FooterLinks
          translate={t}
          items={items}
        />
      </FooterColumn>
      <FooterColumn className={styles.mobileColumn}>
        <div className="logo-block">
          <div className="logo-block__content">
            <ContactInfo />
          </div>
        </div>
      </FooterColumn>
      <div className="l-footer__copyright">
        <p>{t('footer.copyright')}</p>
      </div>
    </>
  );
};

MobileLayout.propTypes = {
  items: arrayOf(string).isRequired
};

export default MobileLayout;
