import { useEffect } from 'react';
import { isMobileChrome } from '../util';
import { LARGE_TABLET_MEDIA } from '../constants';

const useFocusTrap = (closeAndFocusToToggle, ref, isMobileMenuOpen, isMobileQuickLinksOpen) => {
  const isMobileScreenSize = () => (window.matchMedia(LARGE_TABLET_MEDIA).matches);
  const isMobileDevice = isMobileScreenSize();

  let justClicked = false;
  let justClickedTimer = null;

  const startClickedTimeout = () => {
    if (justClickedTimer) {
      window.clearTimeout(justClickedTimer);
    }
    justClicked = true;
    justClickedTimer = window.setTimeout(() => {
      justClicked = false;
    }, 200);
  };

  const isOverlayClass = element => element?.className?.includes('overlay');

  const closeIfFocusOutside = () => {
    if (!justClicked) {
      window.setTimeout(() => {
        const isFocusOutside = ref.current && !ref.current.contains(document.activeElement);
        const isActiveOverlay = isOverlayClass(document.activeElement);
        const isCurrentOverlay = isOverlayClass(ref.current);

        // need to check if focus is on body which is the case within Mobile Chrome
        // Mobile Chrome uses Blick engine which handles focus differently
        const isMobileChromeFocus = isMobileChrome() && document.activeElement === document.body;

        // for the case NVDA screen reader is used, mobile menu is open and overlay is not active, but its opened via NVDA
        // prevent closing mobile menu when focus goes on overlay
        const isMobileMenuNotOverlay = (isMobileMenuOpen && !isActiveOverlay && isFocusOutside && !isMobileChromeFocus);

        // Safari does not support aria-modal attribute
        // https://a11ysupport.io/tech/aria/aria-modal_attribute#support-table-2
        // for the case VO screen reader is used, view is not overlay and navigatin outside to menu
        // prevent closing mobile menu when focus goes outside menu
        const isNotMobileMenuOverlay = (!isMobileMenuOpen && !isCurrentOverlay && isFocusOutside && !isMobileChromeFocus);

        // for overlay views, outside click
        const isOutsideMenuClosed = isFocusOutside && isMobileMenuNotOverlay === undefined && !isMobileChromeFocus;

        if ((isMobileMenuNotOverlay && isNotMobileMenuOverlay) || isOutsideMenuClosed) {
          closeAndFocusToToggle();
        }
      }, 100);
    }
  };

  useEffect(() => {
    const element = ref.current;
    const isOverlay = isOverlayClass(document.activeElement);

    if (!isMobileDevice && !isMobileMenuOpen && !isOverlay) {
      return () => {};
    }

    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        closeAndFocusToToggle();
      }
    };

    const handleTab = (event) => {
      if (element) {
        /*
            .mobileLinks class is not visible, but querySelectorAll still finds its children
            so we filter then away if window width is more than 767px (mediumTable breakpoint)
            WE DO NOT have to filter them away if window width is less than 768px
            We are unable to get elements here that way we could filter non-visible mobileLinks children directly
        */
        let focusableElements = [...element.querySelectorAll(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        )].filter((focusableElement) => {
          if (window.innerWidth > 767 && isOverlay) {
            return !focusableElement.parentElement.className.includes('mobileLinks');
          }
          return true;
        });

        if (isMobileMenuOpen) {
          focusableElements = focusableElements.filter(focusableElement =>
            focusableElement.className.includes('menu')
            || (isMobileQuickLinksOpen && focusableElement.className.includes('quickLink') && focusableElement.className.includes('externalLink')));
        }

        const firstElement = focusableElements[0];
        const lastElement = focusableElements[focusableElements.length - 1];

        if (event.key === 'Tab') {
          if (event.shiftKey && document.activeElement === firstElement) {
            event.preventDefault();
            lastElement.focus();
          } else if (!event.shiftKey && document.activeElement === lastElement) {
            event.preventDefault();
            firstElement.focus();
          }
        }
      }
    };

    window.addEventListener('keydown', handleEsc);
    window.addEventListener('keydown', handleTab);

    return () => {
      window.removeEventListener('keydown', handleEsc);
      window.removeEventListener('keydown', handleTab);
    };
  }, [closeAndFocusToToggle, ref, isMobileMenuOpen, isMobileQuickLinksOpen, isMobileDevice]);

  return { startClickedTimeout, closeIfFocusOutside };
};

export default useFocusTrap;
