import React from 'react';
import { string, arrayOf } from 'prop-types';

import QuickLink from '../../QuickLinks/QuickLink';
import styles from './mobileLinkGroup.css';
import { getServiceLinksAlphabetizer } from '../../../util';
import useTranslation from '../../../hooks/useTranslation';

const MobileLinkGroup = ({
  categoryName,
  services,
  extraClassName
}) => {
  const { t, lang } = useTranslation();
  const alphabetizeServiceLinks = getServiceLinksAlphabetizer(t, lang);

  return (
    <li key={categoryName} className={`${styles.linkGroup} ${extraClassName || ''}`}>
      <h3 className={styles.groupTitle}>
        {t(categoryName)}
      </h3>
      <ul className={styles.quickLinks} aria-label={t(categoryName)}>
        {alphabetizeServiceLinks(services).map(service =>
          <QuickLink service={service} key={service} isMobile />)}
      </ul>
    </li>
  );
};

MobileLinkGroup.propTypes = {
  categoryName: string.isRequired,
  services: arrayOf(string).isRequired,
  extraClassName: string
};

export default MobileLinkGroup;
