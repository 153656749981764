export const mostUsed = {
  name: 'quickLinks.nameMostUsed',
  services: [
    'email',
    'moodle',
    'helka',
    'sisu',
    'mot'
  ]
};

export const studyTools = {
  name: 'quickLinks.nameStudyTools',
  services: [
    'aatos',
    'changePassword',
    'email',
    'ethesis',
    'examinarium',
    'itSecurityTest',
    'jobTeaser',
    'mobilityOnline',
    'moodle',
    'norppa',
    'oili',
    'searchForDegreeProgrammes',
    'sisu',
    'thessa',
    'unihow',
    'unitube',
    'wiki'
  ]
};

export const informationSearch = {
  name: 'quickLinks.nameInformationSearch',
  services: [
    'flamma',
    'helka',
    'mot',
    'nationalLibrary',
    'researchGuides'
  ]
};

export const contactInformation = {
  name: 'quickLinks.nameContactInformation',
  services: [
    'appointmentsToStudentServices',
    'guidanceCompass',
    'helpdesk',
    'peopleFinder'
  ]
};

export const everydayLifeAndWellbeing = {
  name: 'quickLinks.nameEverydayLifeAndWellbeing',
  services: [
    'fshs',
    'hoas',
    'hsl',
    'hyy',
    'kela',
    'nyyti',
    'unicafe',
    'unisport'
  ]
};
