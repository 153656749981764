import React, { useRef, useEffect } from 'react';
import {
  func,
  string,
  bool
} from 'prop-types';

import {
  mostUsed, studyTools, informationSearch, contactInformation,
  everydayLifeAndWellbeing
} from '../../QuickLinks/services';
import MobileLinkGroup from './MobileLinkGroup';
import {
  appConfigsType,
  notificationsInfoType,
  userRoleTypeValues,
  userType
} from '../../../constants/types';

import ExternalAppLink from '../../ExternalAppLink';
import NotificationsButton from '../../NotificationsButton';
import HelkaCardButton from '../../HelkaCardButton';
import styles from './mobileMenu.css';
import useTranslation from '../../../hooks/useTranslation';

const NavigationItem = ({ app, isFocus, url }) => {
  const { t } = useTranslation();
  const anchorRef = useRef();

  useEffect(() => {
    if (isFocus) {
      anchorRef.current.focus();
    }
  }, [isFocus]);

  return (
    <li className={styles.menuItem}>
      <a href={url} ref={anchorRef} className={styles.menuText}>
        {t(`apps.${app}.navigation`)}
      </a>
    </li>
  );
};

NavigationItem.propTypes = {
  app: string.isRequired,
  isFocus: bool.isRequired,
  url: string
};

const getNavigationItems = (appConfigs, langCode, hasFirstFocus) =>
  Object.entries(appConfigs).map(([app, config], i) => {
    const navigationUrl = () => (
      (config.navigation.urls && config.navigation.urls[langCode])
        ? config.navigation.urls[langCode]
        : config.navigation.url);

    const isFirst = i === 0;
    return config && (
      <NavigationItem
        key={app}
        app={app}
        isFocus={isFirst && hasFirstFocus}
        url={navigationUrl()}
      />
    );
  });

const MobileMenu = ({
  appConfigs,
  isQuickLinksOpen,
  handleQuickLinksClick,
  toggleMenu,
  notificationsInfo,
  user
}) => {
  const { t, lang } = useTranslation();
  const quickLinksIcon = isQuickLinksOpen ? `icon--caret-up ${styles.quickLinksIcon}` : `icon--caret-down ${styles.quickLinksIcon}`;
  const { showNotifications, unreadNotificationCount } = notificationsInfo;
  const showHelka = !!user; // show Helka button when user is logged in

  return (
    <div className={styles.menuContainer}>
      <nav>
        <ul className={`${styles.menuItems} ${styles.topRowItems}`}>
          {showHelka && (
            <li className={styles.menuItem}>
              <HelkaCardButton
                isFocus
                toggleMenu={toggleMenu}
              />
            </li>
          )}
          {showNotifications && (
            <li className={styles.menuItem}>
              <NotificationsButton
                unreadNotificationCount={unreadNotificationCount}
                toggleMenu={toggleMenu}
              />
            </li>
          )}
        </ul>
        <ul className={styles.menuItems}>
          {getNavigationItems(appConfigs, lang, !showNotifications && !showHelka)}
          <li className={styles.divider} />
          <li className={styles.menuItem}>
            <ExternalAppLink appName="sisu" className={`${styles.menuText} ${styles.externalLink}`} />
          </li>
          {user?.roles?.includes(userRoleTypeValues.TEACHER) && (
            <li className={styles.menuItem}>
              <ExternalAppLink appName="teachersFlamma" className={`${styles.menuText} ${styles.externalLink}`} />
            </li>
          )}
          <li className={styles.divider} />
          <li className={styles.menuItem}>
            <button
              className={`${styles.quickLinksButton} ${styles.menuText}`}
              onClick={handleQuickLinksClick}
              type="button"
              aria-expanded={isQuickLinksOpen}
            >
              {t('quickLinks.nameHeading')}
              <span className={quickLinksIcon} aria-hidden="true" />
            </button>
          </li>
          { isQuickLinksOpen && (
            <>
              <MobileLinkGroup categoryName={mostUsed.name} services={mostUsed.services} extraClassName={styles.mostUsed} />
              { [studyTools, informationSearch, contactInformation, everydayLifeAndWellbeing]
                .map(({ name, services }) => <MobileLinkGroup categoryName={name} services={services} extraClassName={styles.linkGroup} />)}
            </>
          )}
        </ul>
      </nav>
    </div>
  );
};

MobileMenu.propTypes = {
  appConfigs: appConfigsType.isRequired,
  notificationsInfo: notificationsInfoType.isRequired,
  isQuickLinksOpen: bool.isRequired,
  handleQuickLinksClick: func.isRequired,
  toggleMenu: func.isRequired,
  user: userType
};

export default MobileMenu;
