import React from 'react';

import styles from '../footer.css';
import useTranslation from '../../../hooks/useTranslation';

const ServiceName = () => {
  const { t } = useTranslation();
  return (
    <div className={`logo__sitename ${styles.serviceName}`}>
      {t('footer.name')}
      <div className={styles.serviceUrl}>{t('footer.url')}</div>
    </div>
  );
};

export default ServiceName;
