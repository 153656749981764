import React, { useRef } from 'react';
import {
  elementType,
  func,
  string,
  bool
} from 'prop-types';

import {
  mostUsed, studyTools, informationSearch, contactInformation,
  everydayLifeAndWellbeing
} from '../../QuickLinks/services';
import MobileLinkGroup from './MobileLinkGroup';
import {
  appConfigsType,
  notificationsInfoType,
  userRoleTypeValues,
  userType,
  helkaCardButtonInfoType
} from '../../../constants/types';

import ExternalAppLink from '../../ExternalAppLink';
import NotificationsButton from '../../NotificationsButton';
import HelkaCardButton from '../../HelkaCardButton';
import styles from './mobileMenu.css';
import useTranslation from '../../../hooks/useTranslation';

const NavigationItem = ({ app, url }) => {
  const { t } = useTranslation();
  const anchorRef = useRef();

  return (
    <li className={styles.menuItem}>
      <a href={url} ref={anchorRef} className={styles.menuText}>
        {t(`apps.${app}.navigation`)}
      </a>
    </li>
  );
};

NavigationItem.propTypes = {
  app: string.isRequired,
  url: string
};

const getNavigationItems = (appConfigs, langCode) =>
  Object.entries(appConfigs).map(([app, config]) => {
    const navigationUrl = () => (
      (config.navigation.urls && config.navigation.urls[langCode])
        ? config.navigation.urls[langCode]
        : config.navigation.url);

    return config && (
      <NavigationItem
        key={app}
        app={app}
        url={navigationUrl()}
      />
    );
  });

const MobileMenu = ({
  appConfigs,
  isQuickLinksOpen,
  handleQuickLinksClick,
  toggleMenu,
  notificationsInfo,
  user,
  MenuButton,
  isMenuOpen,
  helkaCardButtonInfo,
  closeAndFocusToToggle,
  toggleMobileMenu
}) => {
  const { t, lang } = useTranslation();
  const quickLinksIcon = isQuickLinksOpen ? `icon--caret-up ${styles.quickLinksIcon}` : `icon--caret-down ${styles.quickLinksIcon}`;
  const { showNotifications, unreadNotificationCount } = notificationsInfo;
  const showHelka = !!user; // show Helka button when user is logged in

  return (
    <div
      className={styles.menuContainer}
      role="dialog"
      aria-label={t('header.content')}
      aria-modal="true"
    >
      <nav>
        <MenuButton
          isMenuOpen={isMenuOpen}
          notificationsInfo={notificationsInfo}
          helkaCardButtonInfo={helkaCardButtonInfo}
          closeAndFocusToToggle={closeAndFocusToToggle}
          toggleMobileMenu={toggleMobileMenu}
        />
        <ul className={`${styles.menuItems} ${styles.topRowItems}`}>
          {showHelka && (
            <li className={styles.menuItem}>
              <HelkaCardButton
                isFocus
                toggleMenu={toggleMenu}
              />
            </li>
          )}
          {showNotifications && (
            <li className={styles.menuItem}>
              <NotificationsButton
                unreadNotificationCount={unreadNotificationCount}
                toggleMenu={toggleMenu}
              />
            </li>
          )}
        </ul>
        <ul className={styles.menuItems}>
          {getNavigationItems(appConfigs, lang)}
          <li className={styles.divider} />
          <li className={styles.menuItem}>
            <ExternalAppLink appName="sisu" className={`${styles.menuText} ${styles.externalLink}`} />
          </li>
          {user?.roles?.includes(userRoleTypeValues.TEACHER) && (
            <li className={styles.menuItem}>
              <ExternalAppLink appName="teachersFlamma" className={`${styles.menuText} ${styles.externalLink}`} />
            </li>
          )}
          <li className={styles.divider} />
          <li className={styles.menuItem}>
            <button
              className={`${styles.quickLinksButton} ${styles.menuText}`}
              onClick={handleQuickLinksClick}
              type="button"
              aria-expanded={isQuickLinksOpen}
            >
              {t('quickLinks.nameHeading')}
              <span className={quickLinksIcon} aria-hidden="true" />
            </button>
          </li>
          { isQuickLinksOpen && (
            <>
              <MobileLinkGroup categoryName={mostUsed.name} services={mostUsed.services} extraClassName={styles.mostUsed} />
              { [studyTools, informationSearch, contactInformation, everydayLifeAndWellbeing]
                .map(({ name, services }) => (
                  <MobileLinkGroup key={name} categoryName={name} services={services} extraClassName={styles.linkGroup} />
                ))}
            </>
          )}
        </ul>
      </nav>
    </div>
  );
};

MobileMenu.propTypes = {
  appConfigs: appConfigsType.isRequired,
  isQuickLinksOpen: bool.isRequired,
  handleQuickLinksClick: func.isRequired,
  toggleMenu: func.isRequired,
  notificationsInfo: notificationsInfoType.isRequired,
  user: userType,
  MenuButton: elementType.isRequired,
  isMenuOpen: bool.isRequired,
  helkaCardButtonInfo: helkaCardButtonInfoType.isRequired,
  closeAndFocusToToggle: func.isRequired,
  toggleMobileMenu: func.isRequired
};

MobileMenu.propTypes = {
  appConfigs: appConfigsType.isRequired,
  notificationsInfo: notificationsInfoType.isRequired,
  isQuickLinksOpen: bool.isRequired,
  handleQuickLinksClick: func.isRequired,
  toggleMenu: func.isRequired,
  user: userType,
  MenuButton: elementType.isRequired,
  helkaCardButtonInfo: helkaCardButtonInfoType.isRequired
};

export default MobileMenu;
