import React from 'react';
import { node, string } from 'prop-types';

import { trackObarLinkClick } from '../../tracking';
import translations from '../../i18n/translations.json';
import ExternalLink from '../ExternalLink';
import useTranslation from '../../hooks/useTranslation';

const ExternalAppLink = ({
  appName, className, iconClass
}) => {
  const { t } = useTranslation();

  if (!Object.keys(translations.links).includes(appName)) {
    return null;
  }

  return (
    <ExternalLink
      href={t(`links.${appName}.url`)}
      className={className || ''}
      iconClass={iconClass}
      onClick={() => trackObarLinkClick(appName)}
      onAuxClick={() => trackObarLinkClick(appName)}
    >
      {t(`links.${appName}.name`)}
    </ExternalLink>
  );
};

ExternalAppLink.propTypes = {
  appName: string.isRequired,
  className: node,
  iconClass: node
};

export default ExternalAppLink;
