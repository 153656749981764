import React from 'react';
import { func } from 'prop-types';
import onClickOutsideHOC from 'react-onclickoutside';

import styles from './quickLinks.css';
import {
  mostUsed, studyTools, informationSearch, contactInformation,
  everydayLifeAndWellbeing
} from './services';
import QuickLinkGroup from './QuickLinkGroup';
import HighlightedLinkGroup from './HighlightedLinkGroup';
import useTranslation from '../../hooks/useTranslation';

const QuickLinksMenu = ({ onClickOutside }) => {
  const { t } = useTranslation();

  QuickLinksMenu.handleClickOutside = () => onClickOutside();

  return (
    <nav className={styles.menuContainer}>
      <div className={styles.menuContent}>
        <h2 className={styles.name}>{t('quickLinks.nameHeading')}</h2>
        <HighlightedLinkGroup categoryName={mostUsed.name} services={mostUsed.services} />
        <div className={styles.linksContainer}>
          <div className={styles.wideContainer}>
            <QuickLinkGroup categoryName={studyTools.name} services={studyTools.services} wide />
          </div>
          <div className={styles.narrowContainer}>
            <QuickLinkGroup categoryName={informationSearch.name} services={informationSearch.services} />
            <QuickLinkGroup categoryName={contactInformation.name} services={contactInformation.services} />
          </div>
          <div className={styles.narrowContainer}>
            <QuickLinkGroup categoryName={everydayLifeAndWellbeing.name} services={everydayLifeAndWellbeing.services} />
          </div>
        </div>
        <button className={styles.closeButton} type="button" onClick={onClickOutside}>
          {t('quickLinks.close')}
          <span className="icon--remove" aria-hidden="true" />
        </button>
      </div>
    </nav>
  );
};

const clickOutsideConfig = {
  handleClickOutside: () => QuickLinksMenu.handleClickOutside
};

QuickLinksMenu.propTypes = {
  onClickOutside: func.isRequired
};

export default onClickOutsideHOC(QuickLinksMenu, clickOutsideConfig);
