import { bool, func } from 'prop-types';
import React from 'react';
import { HELKA_CARD_OVERLAY } from '../../constants';
import { refType } from '../../constants/types';

import styles from './helkaCardButton.css';
import OverlayToggleButton from '../OverlayToggleButton';
import useTranslation from '../../hooks/useTranslation';

const HelkaCardButton = ({
  toggleMenu, isFocus = false, helkaCardReturnRef
}) => {
  const { t } = useTranslation();
  return (
    <OverlayToggleButton
      returnRef={helkaCardReturnRef}
      label={t('helkaCard')}
      toggleMenu={toggleMenu}
      isFocus={isFocus}
      overlayName={HELKA_CARD_OVERLAY}
    >
      <span className={styles.icon} />
    </OverlayToggleButton>
  );
};

HelkaCardButton.propTypes = {
  isFocus: bool,
  helkaCardReturnRef: refType,
  toggleMenu: func
};

export default HelkaCardButton;
