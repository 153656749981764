import { func } from 'prop-types';
import React, {
  useCallback, useEffect, useState
} from 'react';
import { useHelkaCard } from '../../../context/HelkaCardContext/HelkaCardContextProvider';
import { THEMES } from '../../../context/ThemeContext';
import { useTheme } from '../../../context/ThemeContext/ThemeContextProvider';
import ExternalLink from '../../ExternalLink';

import styles from '../helkaCardOverlay.css';
import useTranslation from '../../../hooks/useTranslation';

const Links = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const iconColor = theme === THEMES.DARK ? 'white' : 'blue';

  return (
    <>
      <ExternalLink className={styles.overlayLink} href={t('helkaCardOverlay.cardAndLibraryRightsUrl')} iconColor={iconColor}>{t('helkaCardOverlay.cardAndLibraryRights')}</ExternalLink>
      <ExternalLink className={styles.overlayLink} href={t('helkaCardOverlay.libraryUrl')} iconColor={iconColor}>{t('helkaCardOverlay.toLibraryPages')}</ExternalLink>
    </>
  );
};

const isPinValid = pinCode => /^[0-9]{4}$/.test(pinCode);

const arePinsValid = (pinCode, repeatedPinCode) => isPinValid(pinCode) && repeatedPinCode === pinCode;

const CreateHelkaCard = ({
  setShowCreateHelkaCardView, setHelkaCardCreatedMessage
}) => {
  const { t } = useTranslation();
  const { createHelkaCard } = useHelkaCard();
  const [pinCode, setPinCode] = useState('');
  const [repeatedPinCode, setRepeatedPinCode] = useState('');
  const [pinCodeErrorMessage, setPinCodeErrorMessage] = useState(null);
  const [repeatPinCodeErrorMessage, setRepeatPinCodeErrorMessage] = useState(null);
  const [saveCardErrorMessage, setSaveCardErrorMessage] = useState(null);
  const [formIsValid, setFormIsValid] = useState(false);

  useEffect(() => {
    setFormIsValid(arePinsValid(pinCode, repeatedPinCode));
    if (isPinValid(pinCode)) {
      setPinCodeErrorMessage(null);
    }
    if (repeatedPinCode === pinCode) {
      setRepeatPinCodeErrorMessage(null);
    }
  }, [pinCode, repeatedPinCode]); // eslint-disable-line

  const validateAndDisplayErrors = () => {
    if (!isPinValid(pinCode)) {
      setPinCodeErrorMessage(t('helkaCardOverlay.pinCodeValidationError'));
    }
    if (repeatedPinCode !== pinCode) {
      setRepeatPinCodeErrorMessage(t('helkaCardOverlay.repeatPinCodeValidationError'));
    }
  };

  const handleBlurPinCode = () => {
    validateAndDisplayErrors();
  };

  const handleBlurRepeatedPinCode = () => {
    validateAndDisplayErrors();
  };

  const handlePinCodeChange = (e) => {
    setPinCode(e.target.value);
  };

  const handleRepeatPinCodeChange = (e) => {
    setRepeatedPinCode(e.target.value);
  };

  const handleSaveButtonOnClick = useCallback(async (e) => {
    e.preventDefault();
    if (arePinsValid(pinCode, repeatedPinCode)) {
      const responseStatus = await createHelkaCard(pinCode);
      if (responseStatus !== 200) {
        setSaveCardErrorMessage(`${t('helkaCardOverlay.saveHelkaCardError')}${responseStatus}`);
      } else {
        setShowCreateHelkaCardView(false);
        setHelkaCardCreatedMessage(t('helkaCardOverlay.saveHelkaCardSuccess'));
      }
    }
  }, [pinCode, repeatedPinCode, createHelkaCard, setShowCreateHelkaCardView, setHelkaCardCreatedMessage, t]);

  return (
    <div className={styles.overlayContent}>
      <div className={`${styles.leftColumn} ${styles.getCardLeftColumn}`}>
        <div className={styles.title}><h2 id="helkaCardTitle">{t('helkaCard')}</h2></div>
        <div className={styles.createHelkaCardExplanation}>{t('helkaCardOverlay.explanationWithUserLiabilityNotice')}</div>
        <h3 className={`${styles.subTitle} ${styles.getCardSubTitle}`}>{t('helkaCardOverlay.getCard')}</h3>
        <div className={styles.getCardInstructions}>{t('helkaCardOverlay.createCardPinCodeInstructions')}</div>
        <form noValidate className={styles.pinCodeForm}>
          <fieldset className={styles.pinCodeFormFields}>
            <label className={styles.pinCodeField} htmlFor="helkaPinCode">
              <span id="pinCodeLabel" className={styles.formInputLabel}>{t('helkaCardOverlay.pinCode')}</span>
              <input
                id="helkaPinCode"
                value={pinCode}
                maxLength={4}
                size={4}
                className={styles.pinCodeInput}
                onChange={handlePinCodeChange}
                onBlur={handleBlurPinCode}
                type="text"
                inputMode="numeric"
                required
                aria-invalid={!!pinCodeErrorMessage}
                aria-describedby="pinCodeInstructions"
              />
              {pinCodeErrorMessage && (
                <span role="alert" className={styles.pinCodeErrorMessage}>{pinCodeErrorMessage}</span>
              )}
              {repeatPinCodeErrorMessage && (
                <span role="alert" className={styles.pinCodeErrorMessage}>{repeatPinCodeErrorMessage}</span>
              )}
              <div className={styles.pinCodeInstructionsWrapper}>
                <span id="pinCodeInstructions" className={styles.pinCodeInstructions}>{t('helkaCardOverlay.choosePinCodeInstructions')}</span>
                <span id="pinCodeInstructions" className={styles.pinCodeInstructionsAmount}>{t('helkaCardOverlay.choosePinCodeInstructionsAmount')}</span>
              </div>
            </label>
            <label className={styles.repeatPinCodeField} htmlFor="repeatHelkaPinCode">
              <span id="repeatPincodeLabel" className={styles.formInputLabel}>{t('helkaCardOverlay.repeatPincode')}</span>
              <input
                value={repeatedPinCode}
                onChange={handleRepeatPinCodeChange}
                onBlur={handleBlurRepeatedPinCode}
                className={styles.pinCodeInput}
                id="repeatHelkaPinCode"
                maxLength={4}
                size={4}
                type="text"
                inputMode="numeric"
                aria-invalid={!!repeatPinCodeErrorMessage}
                required
              />
            </label>
          </fieldset>
          <div className={styles.pinCodeFormActions}>
            <button
              className={`button ${styles.saveHelkaCardButton}`}
              onClick={handleSaveButtonOnClick}
              type="submit"
              aria-disabled={!formIsValid}
              aria-describedby={!formIsValid ? 'saveButtonDisabledReason' : null}
            >
              {t('helkaCardOverlay.saveHelkaCard')}
            </button>
            {!formIsValid && (
              <span id="saveButtonDisabledReason" role="tooltip" className="sr-only">
                {[pinCodeErrorMessage, repeatPinCodeErrorMessage].filter(Boolean)[0]}
              </span>
            )}
            {saveCardErrorMessage && <span role="alert" className={styles.saveCardErrorMessage}>{saveCardErrorMessage}</span>}
          </div>
        </form>
        <div className={styles.createHelkaCardMobileLinks}>
          <Links />
        </div>
      </div>
    </div>
  );
};

CreateHelkaCard.propTypes = {
  setShowCreateHelkaCardView: func.isRequired,
  setHelkaCardCreatedMessage: func.isRequired
};

export default CreateHelkaCard;
