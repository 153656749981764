const DEFAULT_BASE_URL = 'https://obar.it.helsinki.fi';
export const OBAR_HEADER_ROOT_ELEMENT_ID = 'obar-header';
export const OBAR_FOOTER_ROOT_ELEMENT_ID = 'obar-footer';
export const OBAR_FEEDBACK_ROOT_ELEMENT_ID = 'obar-feedback';

export const getHeaderRoot = () => document.getElementById(OBAR_HEADER_ROOT_ELEMENT_ID);

export const getFooterRoot = () => document.getElementById(OBAR_FOOTER_ROOT_ELEMENT_ID);

export const getFeedbackRoot = () => document.getElementById(OBAR_FEEDBACK_ROOT_ELEMENT_ID);

export const getRootAttribute = (attributeName) => {
  const root = getHeaderRoot() || getFeedbackRoot();

  return root && root.getAttribute(attributeName)
    ? root.getAttribute(attributeName)
    : null;
};

const getUrlFromRootAttribute = (attributeName) => {
  const url = getRootAttribute(attributeName);
  if (url && url.startsWith(':')) {
    const { protocol, hostname } = window.location;
    return `${protocol}//${hostname}${url}`;
  }

  return url;
};

export const readRequiredAttrFromDom = (sourceAttributeName) => {
  const value = getRootAttribute(sourceAttributeName);
  if (value === null) {
    throw Error(`Mandatory DOM attribute ${sourceAttributeName} missing from element with id "${OBAR_HEADER_ROOT_ELEMENT_ID}".`);
  }
  return value;
};

export const BASE_URL = getUrlFromRootAttribute('data-base-url') || DEFAULT_BASE_URL;
