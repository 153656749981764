export const availableLanguages = ['fi', 'sv', 'en'];
export const DEFAULT_LANG = 'fi';
export const LANG_COOKIE_NAME = 'OBAR_LANG';
export const LANG_COOKIE_EXPIRES = 365;
export const MEDIUM_TABLET_MEDIA = '(max-width: 767px)';
export const LARGE_TABLET_MEDIA = '(max-width: 959px)';
export const deviceSizes = {
  SMALL_TABLET: 2,
  LARGE_TABLET: 1,
  DESKTOP: 0
};
export const NOTIFICATIONS_OVERLAY = 'NotificationsOverlay';
export const HELKA_CARD_OVERLAY = 'HelkaCardOverlay';
export const SEARCH_OVERLAY = 'SearchOverlay';

export const FOOTER_LINKS = [
  'guidanceCompass',
  'bulletin',
  'contact',
  'admissionServices',
  'library',
  'flamma',
  'campuses',
  'facilityAccessibilityStatement',
  'dataProtectionStatement',
  'cookieSettings',
  'accessibilityStatement'
];

export const DATE_TIME_FORMAT = {
  fi: 'd.M.yyyy H:mm',
  sv: 'd.M.yyyy H:mm',
  en: 'd/M/yyyy h:mm a'
};
