import React from 'react';
import { func, bool } from 'prop-types';
import {
  appConfigsType,
  helkaCardButtonInfoType,
  notificationsInfoType,
  searchButtonInfoType,
  userType
} from '../../constants/types';
import MobileMenu from './MobileMenu';

import styles from './actions.css';
import { NotificationBadge } from '../NotificationsButton';
import { mergeRefs } from '../../util';
import SearchButton from '../SearchButton';
import useTranslation from '../../hooks/useTranslation';

const MenuButton = ({
  isMenuOpen,
  notificationsInfo,
  helkaCardButtonInfo,
  closeAndFocusToToggle,
  toggleMobileMenu
}) => {
  const { t } = useTranslation();

  const buttonIcon = isMenuOpen ? `icon--remove ${styles.closeButton}` : 'icon--hamburger';
  const buttonAriaLabel = isMenuOpen ? 'closeMenu' : 'openMenu';

  const { unreadNotificationCount, notificationsReturnRef } = notificationsInfo;
  const { showNotifications } = notificationsInfo;

  const showBadge = showNotifications && !isMenuOpen;
  const { helkaCardReturnRef } = helkaCardButtonInfo;

  const labelId = 'obar-menu-icon';
  const menuUnreadIndicatorId = 'obar-menu-unread-notifications-indicator';

  const menuButtonStyles = isMenuOpen ? `${styles.menuButton} ${styles.menuCloseButton}` : `${styles.menuButton}`;

  const handleMenuButtonClick = () => {
    if (isMenuOpen) {
      closeAndFocusToToggle();
    } else {
      toggleMobileMenu();
    }
  };

  return (
    <button
      /* eslint-disable-next-line jsx-a11y/no-autofocus */
      autoFocus={isMenuOpen}
      aria-haspopup={!isMenuOpen ? 'dialog' : undefined}
      type="button"
      className={menuButtonStyles}
      onClick={handleMenuButtonClick}
      ref={mergeRefs(notificationsReturnRef, helkaCardReturnRef)}
      aria-labelledby={[labelId, menuUnreadIndicatorId].join(' ')}
    >
      <span id={labelId} className={buttonIcon} aria-label={t(buttonAriaLabel)} />
      {showBadge && <NotificationBadge unreadNotificationCount={unreadNotificationCount} id={menuUnreadIndicatorId} />}
    </button>
  );
};

MenuButton.propTypes = {
  isMenuOpen: bool.isRequired,
  notificationsInfo: notificationsInfoType.isRequired,
  helkaCardButtonInfo: helkaCardButtonInfoType.isRequired,
  closeAndFocusToToggle: func.isRequired,
  toggleMobileMenu: func.isRequired
};

const MobileActions = ({
  isMenuOpen,
  isQuickLinksOpen,
  toggleMobileMenu,
  handleQuickLinksClick,
  appConfigs,
  notificationsInfo,
  helkaCardButtonInfo,
  searchButtonInfo,
  user,
  closeAndFocusToToggle
}) => {
  const { searchReturnRef, showSearchButton } = searchButtonInfo;

  return (
    <div className={styles.mobileActions}>
      {showSearchButton && !isMenuOpen && (
        <>
          <SearchButton searchReturnRef={searchReturnRef} showLabel={false} />
        </>
      )}
      { !isMenuOpen && (
        <MenuButton
          isMenuOpen={isMenuOpen}
          notificationsInfo={notificationsInfo}
          helkaCardButtonInfo={helkaCardButtonInfo}
          closeAndFocusToToggle={closeAndFocusToToggle}
          toggleMobileMenu={toggleMobileMenu}
        />
      )}
      { isMenuOpen && (
        <MobileMenu
          appConfigs={appConfigs}
          notificationsInfo={notificationsInfo}
          isQuickLinksOpen={isQuickLinksOpen}
          handleQuickLinksClick={handleQuickLinksClick}
          toggleMenu={toggleMobileMenu}
          user={user}
          MenuButton={MenuButton}
          isMenuOpen={isMenuOpen}
          helkaCardButtonInfo={helkaCardButtonInfo}
          closeAndFocusToToggle={closeAndFocusToToggle}
          toggleMobileMenu={toggleMobileMenu}
        />
      )}
    </div>
  );
};

MobileActions.propTypes = {
  isMenuOpen: bool.isRequired,
  isQuickLinksOpen: bool.isRequired,
  toggleMobileMenu: func.isRequired,
  handleQuickLinksClick: func.isRequired,
  appConfigs: appConfigsType.isRequired,
  notificationsInfo: notificationsInfoType.isRequired,
  helkaCardButtonInfo: helkaCardButtonInfoType.isRequired,
  searchButtonInfo: searchButtonInfoType.isRequired,
  user: userType,
  closeAndFocusToToggle: func.isRequired
};

export default MobileActions;
