import React from 'react';
import {
  bool, func, arrayOf, string
} from 'prop-types';

import FooterLink from './FooterLink';

const FooterLinks = ({ translate, items, infoFooter }) => (
  <ul className={`list-of-links--condensed ${infoFooter ? 'theme-info-footer' : 'theme-footer'}`}>
    {items.map(item => <FooterLink translate={translate} item={item} key={item} />)}
  </ul>
);

FooterLinks.propTypes = {
  translate: func.isRequired,
  items: arrayOf(string).isRequired,
  infoFooter: bool
};

export default FooterLinks;
