import React from 'react';
import { string, arrayOf } from 'prop-types';

import QuickLink from './QuickLink';
import styles from './highlightedLinkGroup.css';
import useTranslation from '../../hooks/useTranslation';

const HighlightedLinkGroup = ({
  categoryName,
  services
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>
        {t(categoryName)}
      </h3>
      <ul className={styles.linksContainer}>
        {services.map((service, i) => {
          const isFirst = i === 0;
          return (<QuickLink wide={false} isFocus={isFirst} service={service} key={service} />);
        })}
      </ul>
    </div>
  );
};

HighlightedLinkGroup.propTypes = {
  categoryName: string.isRequired,
  services: arrayOf(string).isRequired
};

export default HighlightedLinkGroup;
