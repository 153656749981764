import React, { useRef } from 'react';
import {
  arrayOf, bool, func, shape
} from 'prop-types';
import { meceNotificationType, refType } from '../../constants/types';
import { useOverlay } from '../../context/OverlayContext';

import styles from './overlay.css';
import { HELKA_CARD_OVERLAY, NOTIFICATIONS_OVERLAY, SEARCH_OVERLAY } from '../../constants';
import HelkaCardOverlay from '../HelkaCardOverlay';
import NotificationsOverlay from '../NotificationsOverlay';
import SearchOverlay from '../SearchOverlay';
import useTranslation from '../../hooks/useTranslation';

const OverlayContainer = ({
  helkaCardOverlayInfo, notificationsOverlayInfo, searchOverlayInfo
}) => {
  const { t } = useTranslation();
  const { activeOverlay, setActiveOverlay } = useOverlay();
  const overlayRef = useRef();
  const closeButtonRef = useRef();

  const titleId = `${activeOverlay}Title`;

  let returnRef;
  let title;
  let overlay;
  let justClicked = false;
  let justClickedTimer = null;

  switch (activeOverlay) {
    case HELKA_CARD_OVERLAY:
      returnRef = helkaCardOverlayInfo.helkaCardReturnRef;
      overlay = <HelkaCardOverlay isLoggedIn={helkaCardOverlayInfo.isLoggedIn} />;
      break;
    case NOTIFICATIONS_OVERLAY:
      returnRef = notificationsOverlayInfo.notificationsReturnRef;
      title = t('notifications');
      overlay = (
        <NotificationsOverlay
          notifications={notificationsOverlayInfo.notifications}
          markRead={notificationsOverlayInfo.markRead}
        />
      );
      break;
    case SEARCH_OVERLAY:
      returnRef = searchOverlayInfo.searchReturnRef;
      title = t('search.title');
      overlay = (
        <SearchOverlay
          studiesBaseUrl={searchOverlayInfo.studiesBaseUrl}
        />
      );
      break;
    default: return null;
  }

  // Events happen in this order:
  // 1. onMouseDownCapture -- if anywhere on overlay is being clicked, temporarily prevent it from closing.
  // 2. onBlur -- close it if new focus is outside the overlay and it wasn't being clicked.
  // 3. onClick -- put focus to some element in overlay, otherwise focus cannot leave it and send onBlur.

  const startClickedTimeout = () => {
    if (justClickedTimer) {
      window.clearTimeout(justClickedTimer);
    }
    justClicked = true;
    justClickedTimer = window.setTimeout(() => {
      justClicked = false;
    }, 200);
  };

  const closeAndFocusToToggle = () => {
    setActiveOverlay(null);
    window.setTimeout(() => {
      returnRef.current?.focus();
    }, 100);
  };

  const keepFocusOnOverlay = (event) => {
    if (event.target && event.target.tabIndex >= 0) {
      event.target.focus();
    } else {
      closeButtonRef.current.focus();
    }
  };

  const closeIfFocusOutside = () => {
    if (!justClicked) {
      window.setTimeout(() => {
        if (overlayRef.current && !overlayRef.current.contains(document.activeElement)) {
          closeAndFocusToToggle();
        }
      }, 100);
    }
  };

  const enableDarkModeSupport = activeOverlay === HELKA_CARD_OVERLAY;

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/click-events-have-key-events
    <div
      role="dialog"
      aria-labelledby={titleId}
      className={enableDarkModeSupport ? styles.overlayContainerDarkModeEnabled : styles.overlayContainer}
      onBlur={closeIfFocusOutside}
      onMouseDownCapture={startClickedTimeout}
      onClick={keepFocusOnOverlay}
      ref={overlayRef}
    >
      <div className={styles.overlay}>
        <div className={styles.content}>
          <div className={styles.header}>
            <h1 id={titleId}>{title}</h1>
            <button
              /* eslint-disable-next-line jsx-a11y/no-autofocus */
              autoFocus
              type="button"
              aria-label={t('close')}
              className={styles.closeButton}
              onClick={closeAndFocusToToggle}
              ref={closeButtonRef}
            >
              {t('close')}
              <span className="icon--remove" />
            </button>
          </div>
          {overlay}
        </div>
      </div>
    </div>
  );
};

OverlayContainer.propTypes = {
  helkaCardOverlayInfo: shape({
    helkaCardReturnRef: refType,
    isLoggedIn: bool
  }),
  notificationsOverlayInfo: shape({
    notificationsReturnRef: refType,
    notifications: arrayOf(meceNotificationType),
    markRead: func.isRequired
  }),
  searchOverlayInfo: shape({
    searchReturnRef: refType
  })
};

export default OverlayContainer;
