import 'core-js/stable';

import React from 'react';
import { render } from 'react-dom';

import getConfig from './config';
import {
  getFeedbackRoot, getFooterRoot, getHeaderRoot, OBAR_HEADER_ROOT_ELEMENT_ID
} from './config/dom-config';
import App from './components/App';
import Footer from './components/Footer';
import Feedback from './components/Feedback';
import { initializeTracking } from './tracking';
import DeviceSizeContextProvider from './context/DeviceSizeContext/DeviceSizeContextProvider';
import ConfigContext from './context/ConfigContext';
import LangContextProvider from './context/LangContext/LangContextProvider';
import InitializeLang from './components/InitializeLang';

let rootElement;
let footerRootElement;
let feedbackRootElement;
let appConfig;

const renderApp = () => render(
  <LangContextProvider>
    <InitializeLang config={appConfig}>
      <ConfigContext.Provider value={appConfig}>
        <App config={appConfig} />
      </ConfigContext.Provider>
    </InitializeLang>
  </LangContextProvider>,
  rootElement
);

const renderFooter = () => render(
  <LangContextProvider>
    <DeviceSizeContextProvider>
      <InitializeLang config={appConfig}>
        <Footer />
        <ConfigContext.Provider value={appConfig}>
          <Feedback />
        </ConfigContext.Provider>
      </InitializeLang>
    </DeviceSizeContextProvider>
  </LangContextProvider>,
  footerRootElement
);

const renderFeedback = () => render(
  <LangContextProvider>
    <DeviceSizeContextProvider>
      <InitializeLang config={appConfig}>
        <ConfigContext.Provider value={appConfig}>
          <Feedback />
        </ConfigContext.Provider>
      </InitializeLang>
    </DeviceSizeContextProvider>
  </LangContextProvider>,
  feedbackRootElement
);

const getConfigAndRenderApp = () => {
  rootElement = getHeaderRoot();
  footerRootElement = getFooterRoot();
  feedbackRootElement = getFeedbackRoot();

  if (rootElement) {
    getConfig().then((config) => {
      if (config && config.apps) {
        appConfig = config;

        renderApp();

        if (feedbackRootElement) {
          renderFeedback();
        } else if (footerRootElement) {
          renderFooter();
        }

        initializeTracking(config);
      }
    });
  } else if (feedbackRootElement) {
    getConfig().then((config) => {
      if (config && config.feedbackOnly) {
        appConfig = config;
        renderFeedback();
      }
    });
  } else {
    throw Error(`Did not find the DOM element to bind to by ID "${OBAR_HEADER_ROOT_ELEMENT_ID}".`);
  }
};

if (module.hot) {
  if (rootElement) {
    module.hot.accept('./components/App', () => {
      renderApp();
    });
    module.hot.accept('./components/Feedback', () => {
      renderFeedback();
    });
    module.hot.accept('./components/Footer', () => {
      renderFooter();
    });
  } else {
    module.hot.accept('./components/Feedback', () => {
      renderFeedback();
    });
  }
}

getConfigAndRenderApp();
