import React from 'react';
import {
  bool, func, number, string
} from 'prop-types';

import styles from './notificationsButton.css';
import { trackMeceToggle } from '../../tracking';
import { NOTIFICATIONS_OVERLAY } from '../../constants';
import OverlayToggleButton from '../OverlayToggleButton';
import { refType } from '../../constants/types';
import useTranslation from '../../hooks/useTranslation';

export const NotificationBadge = ({ unreadNotificationCount, id }) => {
  const { t } = useTranslation();

  return (unreadNotificationCount ? (
    <span
      className={styles.badge}
      aria-label={`${unreadNotificationCount} ${t('unreadNotifications')}`}
      id={id}
    >
      {unreadNotificationCount}
    </span>
  ) : null);
};

NotificationBadge.propTypes = {
  unreadNotificationCount: number.isRequired,
  id: string
};

const NotificationsButton = ({
  unreadNotificationCount,
  notificationsReturnRef,
  toggleMenu,
  isFocus = false
}) => {
  const { t } = useTranslation();

  const labelId = 'obar-notifications-label';
  const unreadIndicatorId = 'obar-unread-notifications-indicator';

  return (
    <OverlayToggleButton
      returnRef={notificationsReturnRef}
      overlayName={NOTIFICATIONS_OVERLAY}
      isFocus={isFocus}
      toggleMenu={toggleMenu}
      trackOnClick={() => trackMeceToggle(unreadNotificationCount !== 0)}
      label={t('notifications')}
      labelId={labelId}
      ariaLabelledBy={[labelId, unreadIndicatorId]}
      isMobileMenuOpen={false}
    >
      <span className={styles.bell}>
        <NotificationBadge id={unreadIndicatorId} unreadNotificationCount={unreadNotificationCount} />
      </span>
    </OverlayToggleButton>

  );
};

NotificationsButton.propTypes = {
  unreadNotificationCount: number.isRequired,
  notificationsReturnRef: refType,
  toggleMenu: func,
  isFocus: bool
};

export default NotificationsButton;
