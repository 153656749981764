import React from 'react';
import { arrayOf, string } from 'prop-types';
import FooterColumn from '../../FooterColumn';
import FooterLinks from '../../FooterLinks';
import ContactInfo from '../ContactInfo';
import FooterLogo from '../FooterLogo';
import ServiceName from '../ServiceName';

import styles from '../footer.css';
import useTranslation from '../../../hooks/useTranslation';

const DesktopLayout = ({ items }) => {
  const { t } = useTranslation();
  const middleIndex = Math.ceil(items.length / 2);
  const firstColumnItems = items.slice(0, middleIndex);
  const secondColumnItems = items.slice(middleIndex);

  return (
    <>
      <div className={styles.row}>
        <FooterColumn>
          <div className="logo-block">
            <FooterLogo />
            <div className="logo-block__content">
              <ServiceName />
              <ContactInfo />
            </div>
          </div>
        </FooterColumn>
        <FooterColumn className={styles.noMargin}>
          <FooterLinks
            translate={t}
            items={firstColumnItems}
          />
        </FooterColumn>
        <FooterColumn className={styles.noMargin}>
          <FooterLinks
            translate={t}
            items={secondColumnItems}
          />
        </FooterColumn>
      </div>
      <div className={`l-footer__copyright ${styles.row} ${styles.copyright}`}>
        <p>{t('footer.copyright')}</p>
      </div>
    </>
  );
};

DesktopLayout.propTypes = {
  items: arrayOf(string).isRequired
};

export default DesktopLayout;
