import {
  string,
  bool,
  arrayOf,
  func,
  shape,
  number,
  oneOf,
  instanceOf,
  oneOfType
} from 'prop-types';

export const activeLanguageType = shape({
  code: string.isRequired,
  active: bool.isRequired
});

export const translateType = {
  activeLanguage: activeLanguageType,
  languages: arrayOf(activeLanguageType).isRequired,
  setActiveLanguage: func.isRequired
};

export const localizedTextType = shape({
  fi: string,
  sv: string,
  en: string
});

export const languageSelectEndpointType = shape({
  fi: string.isRequired,
  sv: string.isRequired,
  en: string.isRequired
});

export const userRoleTypeValues = {
  STUDENT: 'student',
  TEACHER: 'teacher'
};

export const refType = oneOfType([
  func,
  shape({ current: instanceOf(Element) })
]);

export const notificationsInfoType = shape({
  showNotifications: bool.isRequired,
  unreadNotificationCount: number.isRequired,
  notificationsReturnRef: refType.isRequired
});

export const userType = shape({
  userName: string,
  firstName: string,
  lastName: string,
  roles: arrayOf(oneOf(Object.values(userRoleTypeValues)))
});

export const helkaCardButtonInfoType = shape({
  helkaCardReturnRef: refType
});

export const searchButtonInfoType = shape({
  showSearchButton: bool.isRequired,
  searchReturnRef: refType
});

const requiredPropsCheck = (props, propName, componentName) => {
  const { url, urls } = props;
  if (!url && !urls) {
    return new Error(`One of 'url' or 'urls' is required by '${componentName}' component.`);
  }
  return null;
};

export const navigationConfigType = shape({
  url: requiredPropsCheck,
  urls: requiredPropsCheck
});

export const appConfigType = shape({
  jwtPublicKey: string,
  navigation: navigationConfigType.isRequired
});

export const appConfigsType = shape({
  studies: appConfigType.isRequired,
  myStudies: appConfigType.isRequired,
  guide: appConfigType.isRequired
});

export const meceConfigType = shape({
  domain: string.isRequired,
  jwtToken: string.isRequired
});

export const configType = shape({
  user: userType,
  apps: appConfigsType.isRequired,
  currentLang: string.isRequired,
  loginEndpoint: string,
  logoutEndpoint: string,
  mece: meceConfigType,
  baseUrl: string.isRequired,
  obarRootElementName: string.isRequired,
  appName: string.isRequired,
  contentId: string,
  feedbackOnly: bool,
  matomoTracking: string
});

export const meceNotificationType = shape({
  _id: string.isRequired,
  headingFI: string,
  headingSV: string,
  headingEN: string,
  messageFI: string,
  messageSV: string,
  messageEN: string,
  submitted: string.isRequired,
  read: bool.isRequired
});

export const notificationType = shape({
  read: bool.isRequired,
  heading: string.isRequired,
  id: string.isRequired,
  received: string.isRequired,
  message: string.isRequired,
  linkUrl: string
});

export const announcementTypes = {
  REGULAR: 'REGULAR',
  CRITICAL: 'CRITICAL'
};

export const announcementItemType = shape({
  id: number.isRequired,
  crisisType: oneOf(Object.values(announcementTypes)).isRequired,
  target: string,
  title: localizedTextType.isRequired,
  content: localizedTextType,
  link: localizedTextType,
  linkDescription: localizedTextType,
  modifiedDate: string
});
