import React from 'react';
import Cookies from 'js-cookie';
import {
  languageSelectEndpointType,
  appConfigsType
} from '../../constants/types';
import { LANG_COOKIE_NAME, LANG_COOKIE_EXPIRES, availableLanguages } from '../../constants';
import { transformLocalizedUrl } from '../../util';

import styles from './languageSelector.css';
import { trackLanguageChange } from '../../tracking';
import useTranslation from '../../hooks/useTranslation';

const LanguageSelector = (props) => {
  const { t, lang } = useTranslation();

  const {
    languageSelectEndpoints, apps
  } = props;

  const handleLanguageButtonClick = (langCode) => {
    Cookies.set(LANG_COOKIE_NAME, langCode, { expires: LANG_COOKIE_EXPIRES, domain: '.helsinki.fi', path: '/' });
    trackLanguageChange(langCode);

    const element = document.querySelector(`link[hreflang='${langCode}']`);
    let url;
    if (element) {
      url = element.getAttribute('href');
    }

    if (url) {
      // first look for alternative urls from dom
      window.location.href = url;
    } else if (languageSelectEndpoints) {
      window.location.href = languageSelectEndpoints[langCode];
    } else {
      window.location.href =
        transformLocalizedUrl(window.location.href, lang, langCode, apps);
    }
  };

  const getLanguageSelector = langCode => (
    <li className={styles.languageItem} key={langCode}>
      <button
        type="button"
        lang={langCode}
        id={`languageSelectorButton__${langCode}`}
        onClick={() => handleLanguageButtonClick(langCode)}
      >
        <span className="sr-only">
          {t(`languageSelector.selectLanguage.${langCode}`)}
        </span>
        <span aria-hidden>
          {t(`languageSelector.${langCode}`)}
        </span>
      </button>
    </li>
  );

  return (
    <ul className={styles.languageList}>
      {
        availableLanguages
          .filter(l => l !== lang)
          .map(l => getLanguageSelector(l))
      }
    </ul>
  );
};

LanguageSelector.propTypes = {
  apps: appConfigsType.isRequired,
  languageSelectEndpoints: languageSelectEndpointType
};

export default LanguageSelector;
