import React, {
  useState, useEffect, useContext, useCallback
} from 'react';
import { element } from 'prop-types';

import { deviceSizes, LARGE_TABLET_MEDIA, MEDIUM_TABLET_MEDIA } from '../../constants';

import DeviceSizeContext from './index';

const RESIZE_LISTENER = 'resize';

const getDeviceSize = () => {
  if (window.matchMedia(MEDIUM_TABLET_MEDIA).matches) {
    return deviceSizes.SMALL_TABLET;
  }
  if (window.matchMedia(LARGE_TABLET_MEDIA).matches) {
    return deviceSizes.LARGE_TABLET;
  }
  return deviceSizes.DESKTOP;
};

const DeviceSizeContextProvider = ({ children }) => {
  const [deviceSize, setDeviceSize] = useState(getDeviceSize());

  const handleResize = useCallback(({ size }) => {
    const newSize = getDeviceSize();
    if (size !== newSize) {
      setDeviceSize(newSize);
    }
  }, [setDeviceSize]);

  useEffect(() => {
    window.addEventListener(RESIZE_LISTENER, handleResize);

    return () => {
      window.removeEventListener(RESIZE_LISTENER, handleResize);
    };
  }, [handleResize, deviceSize]);

  return (
    <DeviceSizeContext.Provider value={{ deviceSize }}>
      {children}
    </DeviceSizeContext.Provider>
  );
};

DeviceSizeContextProvider.propTypes = {
  children: element.isRequired
};

export default DeviceSizeContextProvider;

export const useDeviceSize = () => useContext(DeviceSizeContext);
