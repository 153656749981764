import { BASE_URL } from './config/dom-config';

const API_URL = `${BASE_URL}/api`;
const APPLICATION_JSON_TYPE = 'application/json';
const CSRF_PROTECTION_HEADER_NAME = 'X-CSRF-Protection';

const isJSONResponse = (response) => {
  const contentType = response.headers.get('content-type');
  return contentType && contentType.includes(APPLICATION_JSON_TYPE);
};

const createRequestObject = (path, method = 'GET', body) =>
  new Request(path, {
    credentials: 'include',
    headers: {
      ...(method !== 'GET' && { [CSRF_PROTECTION_HEADER_NAME]: 1 }),
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    method,
    body,
    referrerPolicy: 'no-referrer-when-downgrade'
  });

const handleResponse = async (request, promise) => {
  let response;
  try {
    response = await promise;
  } catch {
    // ignore network errors
    return undefined;
  }

  if (!response) {
    throw new Error(`no response for request: ${request?.method} - ${request?.url}`);
  }

  if (response.status === 200 && isJSONResponse(response)) {
    return response.json().catch(() => ({}));
  }

  if (response.ok) {
    return undefined;
  }

  throw new Error(`error response: ${response.status} - ${request.method} ${request.url}`);
};

const getJson = async (path) => {
  const req = createRequestObject(path);
  return handleResponse(req, fetch(req));
};

const postJson = async (path, body) => {
  const req = createRequestObject(path, 'POST', body);
  return handleResponse(req, fetch(req));
};

const getConfigUrl = appName => `${API_URL}/config?appName=${appName}`;

export const getConfigFromBackend = (appName, jwtToken) =>
  postJson(getConfigUrl(appName), JSON.stringify({ jwtToken }));

export const getAnnouncements = (announcementSources, langCode) => {
  const baseQuery = `lang=${langCode || 'en'}`;
  const query = [
    baseQuery,
    announcementSources
      ? `${announcementSources
        .split(',')
        .map(source => `announcementSources[]=${source}`)
        .join('&')}`
      : ''
  ].join('&');
  return getJson(`${API_URL}/announcements?${query}`);
};

export const getHelkaCard = () => fetch(createRequestObject(`${API_URL}/helka/user`));

export const putHelkaCard = pin =>
  fetch(createRequestObject(`${API_URL}/helka/user`, 'PUT', JSON.stringify({ pin })));

export const sendFeedback = (feedback, lang) =>
  postJson(`${API_URL}/feedback?lang=${lang}`, JSON.stringify(feedback));
