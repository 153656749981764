import React from 'react';

import styles from './scrollButton.css';
import Arrow from '../../Arrow';
import useTranslation from '../../../hooks/useTranslation';

const scrollUp = () => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
  const skipId = document.getElementById('skip-to-content');
  const universityLink = document.getElementById('university-link');
  if (skipId) {
    skipId.focus({ preventScroll: true });
  } else {
    universityLink?.focus({ preventScroll: true });
  }
};

const ScrollUpButton = () => {
  const { t } = useTranslation();

  return (
    <button
      type="button"
      className={`button--anchor ${styles.scrollButton}`}
      onClick={() => scrollUp()}
    >
      <Arrow containerClassName={styles.arrow} up />
      <div>{t('footer.scrollUp')}</div>
    </button>
  );
};

export default ScrollUpButton;
