import React from 'react';
import {
  bool, node, string, shape, oneOf
} from 'prop-types';

import Arrow from '../Arrow';
import useTranslation from '../../hooks/useTranslation';

const WITH_LOCALIZE_PROPS_TO_FILTER = [
  'languages', 'defaultLanguage', 'activeLanguage', 'initialize', 'addTranslation',
  'addTranslationForLanguage', 'renderToStaticMarkup', 'setActiveLanguage', 'ignoreTranslateChildren'
];

/**
 * Creates a link that opens in new window with added screen reader hint.
 *
 * If aria-label is provided screen reader hint is appended to it, otherwise it is appended
 * in link body as element visible only to screen reader.
 *
 * Renders external link icon by default. This behaviour can be disabled by defining noIcon
 * in which case wrapped content should in most cases provide its own icon or some other
 * visual indication of opening in new window.
 *
 * External link icon color can be changed with iconColor property. Current allowed
 * values are 'white' (default) and 'blue'.
 *
 * @param {Object} properties
 * @param {string!} properties.href - Link target
 * @param {node!} properties.children - Content to wrap in link
 * @param {string} [properties.className] - External classes for component
 * @param {('black'|'blue'|'white')} [properties.iconColor=white] - Override for external link arrow color
 * @param {string} [properties.iconClass] - Optional class for external link icon
 * @param {boolean} [properties.noIcon] - Don't render external link icon
 * @param {Object} [properties.passedRef] - Optional react ref from useRef from containing component
 * @param {boolean} [properties.arrowRight] - Point arrow to right instead of slanting it upwards
 * @param {...any} [properties.props] - Any optional properties for link
 *
 * @returns {JSX.Element}
 * @constructor
 */
const ExternalLink = ({
  href, children, className, iconClass, noIcon = false, iconColor = 'white', passedRef, arrowRight = false, boxed = false, iconFirst = false, ...props
}) => {
  const { t } = useTranslation();

  const { 'aria-label': ariaLabel, ...propsWithLocalizeIncluded } = props;
  // Clean up not needed properties from withLocalize
  const remainingProps = Object.entries(propsWithLocalizeIncluded)
    .filter(e => !WITH_LOCALIZE_PROPS_TO_FILTER.includes(e[0]))
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      aria-label={ariaLabel ? `${ariaLabel}, ${t('openInNewWindow')}` : undefined}
      ref={passedRef}
      className={`${className || ''}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...remainingProps}
    >
      {/* role "text" is not valid role according to WAI-ARIA definitions (https://www.w3.org/TR/wai-aria/#role_definitions)
          but is used here to circumvent VoiceOver reading links "funnily", see https://axesslab.com/text-splitting/ */}
      {/* eslint-disable-next-line jsx-a11y/aria-role */}
      <span role="text">
        {!iconFirst && children}
        {!noIcon && <Arrow containerClassName={iconClass} color={iconColor} offsite={!arrowRight} boxed={boxed} />}
        {iconFirst && children}
        {!ariaLabel && (
        <span className="sr-only">
          ,
          {' '}
          {t('openInNewWindow')}
        </span>
        )}
      </span>
    </a>
  );
};

ExternalLink.propTypes = {
  href: string.isRequired,
  'aria-label': string,
  className: string,
  children: node.isRequired,
  iconClass: string,
  noIcon: bool,
  iconColor: oneOf(['black', 'blue', 'white']),
  noSrHint: bool,
  passedRef: shape({}),
  arrowRight: bool,
  boxed: bool,
  iconFirst: bool
};

export default ExternalLink;
