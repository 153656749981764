import React, { useRef, useState } from 'react';

import styles from './feedback.css';
import { sendFeedback } from '../../api';
import { useConfig } from '../../context/ConfigContext';
import { trackSendFeedback } from '../../tracking';
import useTranslation from '../../hooks/useTranslation';

const Feedback = () => {
  const states = {
    DEFAULT: 'default',
    FORM: 'form',
    SUBMITTED: 'submitted',
    SUBMITTING: 'submitting',
    ERROR: 'error'
  };

  const { t, lang } = useTranslation();
  const { user } = useConfig();
  const [state, setState] = useState(states.DEFAULT);
  const [wantReply, setWantReply] = useState(false);
  const [message, setMessage] = useState('');
  const [emailAddress, setEmailAddress] = useState(user?.emailAddress || '');
  const focusRef = useRef();

  const handleFeedbackToggle = () => (state === states.FORM ? setState(states.DEFAULT) : setState(states.FORM));

  const formValid = () => message.length > 0 && (!wantReply || (emailAddress.length > 0 && emailAddress.indexOf('@') > 0));

  const cancel = () => {
    setState(states.DEFAULT);
    focusRef.current?.focus();
  };

  const toggleWantReply = () => setWantReply(!wantReply);

  const resetForm = () => {
    setWantReply(false);
    setMessage('');
    setEmailAddress(user?.emailAddress || '');
  };

  const submit = async (e) => {
    e.preventDefault();
    setState(states.SUBMITTING);

    try {
      await sendFeedback({ message, emailAddress: wantReply ? emailAddress : '' }, lang);
      trackSendFeedback();
      setState(states.SUBMITTED);
    } catch {
      setState(states.ERROR);
    }

    resetForm();

    setTimeout(() => {
      setState(states.DEFAULT);
    }, 6000);
  };

  const feedbackTitleId = 'feedback-title';
  const feedbackSectionId = 'feedback-section';

  return (
    <section aria-labelledby={feedbackTitleId} className={`${styles.feedbackContainer} ${state === states.FORM ? 'form' : ''}`}>
      {(state === states.DEFAULT || state === states.FORM || state === states.SUBMITTING) && (
        <button aria-expanded={state === states.FORM || state === states.SUBMITTING} aria-controls={feedbackSectionId} className={styles.feedbackBoxDefault} onClick={handleFeedbackToggle} type="button" tabIndex={0} ref={focusRef}>
          <div className={styles.content}>
            <span className="obar-medium-tablet-hidden icon-2x icon--chat" />
            <div id={feedbackTitleId} className={styles.feedbackBoxLabel}>
              {t('feedback.title')}
            </div>
          </div>
        </button>
      )}
      <div role="status" aria-live="polite" aria-atomic="true">
        {state === states.SUBMITTED && (
          <div className={styles.feedbackBoxSubmitted}>
            <div className={styles.content}>
              <div className="sr-only">
                {t('feedback.done')}
              </div>
              <span className="obar-medium-tablet-hidden icon-2x icon--done" aria-hidden="true" />
              <div className={styles.feedbackBoxLabel} aria-hidden="true">
                {t('feedback.submitted')}
              </div>
            </div>
          </div>
        )}
        {state === states.ERROR && (
        <div className={styles.feedbackBoxError}>
          <div className={styles.content}>
            <div className="sr-only">
              {t('feedback.error')}
            </div>
            <span className="obar-medium-tablet-hidden icon-2x icon--warning" aria-hidden="true" />
            <div className={styles.feedbackBoxLabel} aria-hidden="true">
              {t('feedback.shortError')}
            </div>
          </div>
        </div>
        )}
      </div>
      {(state === states.FORM || state === states.SUBMITTING) && (
        <section className={styles.feedbackBoxForm} aria-label={t('feedback.give')} id={feedbackSectionId}>
          <div className={styles.content}>
            <div className={styles.headingRow}>
              <h2>{t('feedback.title')}</h2>
              <button type="button" className={`${styles.cancel}`} onClick={cancel}>
                <span className="icon--remove" aria-hidden="true" />
                {t('feedback.close')}
              </button>
            </div>
            <form noValidate name="feedbackForm" onSubmit={submit}>
              <div className={styles.feedbackTextarea}>
                <label htmlFor="feedbackTextarea">
                  <span className={styles.textareaLabel}>{t('feedback.label')}</span>
                  <textarea rows="3" cols="5" id="feedbackTextarea" value={message} maxLength="2000" onChange={e => setMessage(e.target.value)} required />
                </label>
              </div>
              <div>
                <label htmlFor="wantReplyToggle" className={styles.checkboxLabel}>
                  <input id="wantReplyToggle" type="checkbox" checked={wantReply} value="true" onChange={toggleWantReply} />
                  {t('feedback.wantReply')}
                </label>
              </div>
              {wantReply && (
                <div>
                  <label htmlFor="email">
                    <span>{t('feedback.email')}</span>
                    <input id="email" type="email" value={emailAddress} onChange={e => setEmailAddress(e.target.value)} required />
                  </label>
                </div>
              )}
              <div className={styles.actions}>
                <input disabled={!formValid() || state !== states.FORM} type="submit" value={t('feedback.submit')} />
              </div>
            </form>
          </div>
        </section>
      )}
    </section>
  );
};

export default Feedback;
