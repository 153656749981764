import React, { useState } from 'react';
import { func, arrayOf } from 'prop-types';

import styles from './notificationsOverlay.css';
import Notification from './Notification';
import { availableLanguages } from '../../constants';
import { meceNotificationType } from '../../constants/types';
import useTranslation from '../../hooks/useTranslation';

const PAGE_SIZE = 8;

const NotificationsOverlay = ({
  notifications, markRead
}) => {
  const { t, lang } = useTranslation();
  const [page, setPage] = useState(0);

  const hasPages = notifications.length > PAGE_SIZE;
  const pageCount = Math.ceil(notifications.length / PAGE_SIZE);
  const pickPreferred = (message, preferredLanguage, prefix) => {
    const langPreference = availableLanguages.map(v => v.toUpperCase()).concat(['']);
    const suffix = [preferredLanguage.toUpperCase()].concat(langPreference)
      .find(aSuffix => (message[prefix + aSuffix]));
    const localizedMessage = message[prefix + suffix];
    return localizedMessage || message[prefix];
  };

  const convert = (message, language) => ({
    // eslint-disable-next-line no-underscore-dangle
    id: message._id,
    received: message.received,
    linkText: pickPreferred(message, language, 'linkText'),
    linkUrl: pickPreferred(message, language, 'linkUrl'),
    message: pickPreferred(message, language, 'message'),
    heading: pickPreferred(message, language, 'heading'),
    source: message.source,
    read: message.read
  });

  return (
    <div className={styles.notificationsContainer}>
      {notifications
        .map(message => convert(message, lang))
        .sort((a, b) => (a.received > b.received ? -1 : 1))
        .slice(PAGE_SIZE * page, PAGE_SIZE * page + PAGE_SIZE)
        .map((message, index) => (
          <Notification
            key={message.id}
            notification={message}
            markRead={markRead}
            isFocus={index === 0}
            lang={lang}
          />
        ))}

      {notifications.length === 0 && t('noNotifications')}

      {hasPages && (
        <div className={styles.pageButtons}>
          {page !== 0 && (
            <button
              type="button"
              className="button"
              onClick={() => setPage(page - 1)}
            >
              {t('previous')}
            </button>
          )}
          {[...Array(pageCount).keys()].map(index => (
            <button
              type="button"
              key={index}
              className={`button ${page === index ? 'is-active' : ''}`}
              onClick={() => setPage(index)}
              disabled={page === index}
            >
              {index + 1}
            </button>
          ))}
          {page !== pageCount - 1 && (
            <button
              type="button"
              className="button"
              onClick={() => setPage(page + 1)}
            >
              {t('next')}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

NotificationsOverlay.propTypes = {
  notifications: arrayOf(meceNotificationType),
  markRead: func.isRequired
};

export default NotificationsOverlay;
