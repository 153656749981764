import React, { useRef, useState, useEffect } from 'react';
import {
  arrayOf, bool, func, shape
} from 'prop-types';
import { meceNotificationType, refType } from '../../constants/types';
import { useOverlay } from '../../context/OverlayContext';

import styles from './overlay.css';
import { HELKA_CARD_OVERLAY, NOTIFICATIONS_OVERLAY, SEARCH_OVERLAY } from '../../constants';
import HelkaCardOverlay from '../HelkaCardOverlay';
import NotificationsOverlay from '../NotificationsOverlay';
import SearchOverlay from '../SearchOverlay';
import useTranslation from '../../hooks/useTranslation';
import useFocusTrap from '../../hooks/useFocusTrap';

const OverlayContainer = ({
  helkaCardOverlayInfo, notificationsOverlayInfo, searchOverlayInfo
}) => {
  const { t } = useTranslation();
  const { activeOverlay, setActiveOverlay } = useOverlay();
  const overlayRef = useRef();
  const focusRef = useRef();
  let returnRef;

  const [showCreateHelkaCardView, setShowCreateHelkaCardView] = useState(false);

  useEffect(() => {
    if (showCreateHelkaCardView && focusRef) {
      focusRef.current?.focus();
    }
  }, [showCreateHelkaCardView, focusRef]);

  const closeButtonRef = useRef();

  const closeAndFocusToToggle = () => {
    setActiveOverlay(null);
    window.setTimeout(() => {
      returnRef?.current?.focus();
    }, 100);
  };

  const {
    startClickedTimeout, closeIfFocusOutside
  } = useFocusTrap(closeAndFocusToToggle, overlayRef);

  const titleId = `${activeOverlay}Title`;

  let title;
  let overlay;

  switch (activeOverlay) {
    case HELKA_CARD_OVERLAY:
      returnRef = helkaCardOverlayInfo.helkaCardReturnRef;
      title = showCreateHelkaCardView ? t('getHelkaCard') : t('helkaCard');
      overlay = (
        <HelkaCardOverlay
          isLoggedIn={helkaCardOverlayInfo.isLoggedIn}
          showCreateHelkaCardView={showCreateHelkaCardView}
          setShowCreateHelkaCardView={setShowCreateHelkaCardView}
        />
      );
      break;
    case NOTIFICATIONS_OVERLAY:
      returnRef = notificationsOverlayInfo.notificationsReturnRef;
      title = t('notifications');
      overlay = (
        <NotificationsOverlay
          notifications={notificationsOverlayInfo.notifications}
          markRead={notificationsOverlayInfo.markRead}
        />
      );
      break;
    case SEARCH_OVERLAY:
      returnRef = searchOverlayInfo.searchReturnRef;
      title = t('search.title');
      overlay = (
        <SearchOverlay
          studiesBaseUrl={searchOverlayInfo.studiesBaseUrl}
        />
      );
      break;
    default: return null;
  }

  // Events happen in this order:
  // 1. onMouseDownCapture -- if anywhere on overlay is being clicked, temporarily prevent it from closing.
  // 2. onBlur -- close it if new focus is outside the overlay and it wasn't being clicked.
  // 3. onClick -- put focus to some element in overlay, otherwise focus cannot leave it and send onBlur.

  const enableDarkModeSupport = activeOverlay === HELKA_CARD_OVERLAY;

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/click-events-have-key-events
    <div
      role="dialog"
      aria-labelledby={titleId}
      aria-modal="true"
      className={enableDarkModeSupport ? styles.overlayContainerDarkModeEnabled : styles.overlayContainer}
      onBlur={closeIfFocusOutside}
      onMouseDownCapture={startClickedTimeout}
      ref={overlayRef}
    >
      <div className={styles.overlay}>
        <div className={styles.container}>
          <div className={styles.content}>
            <button
              /* eslint-disable-next-line jsx-a11y/no-autofocus */
              autoFocus
              type="button"
              aria-label={t('close')}
              className={styles.closeButton}
              onClick={closeAndFocusToToggle}
              ref={closeButtonRef}
            >
              {t('close')}
              <span className="icon--remove" />
            </button>
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
            <h1 className={styles.header} id={titleId} tabIndex={showCreateHelkaCardView ? -1 : undefined} ref={focusRef}>{title}</h1>
          </div>
          {overlay}
        </div>
      </div>
    </div>
  );
};

OverlayContainer.propTypes = {
  helkaCardOverlayInfo: shape({
    helkaCardReturnRef: refType,
    isLoggedIn: bool
  }),
  notificationsOverlayInfo: shape({
    notificationsReturnRef: refType,
    notifications: arrayOf(meceNotificationType),
    markRead: func.isRequired
  }),
  searchOverlayInfo: shape({
    searchReturnRef: refType
  })
};

export default OverlayContainer;
