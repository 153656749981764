import React, { useState, useEffect, useContext } from 'react';
import {
  arrayOf, bool, element, oneOfType
} from 'prop-types';

import HelkaCardContext from './index';
import { getHelkaCard, putHelkaCard } from '../../api';

const noHelkaCardState = {
  barcode: undefined,
  loans: undefined,
  fees: undefined,
  onHold: undefined,
  dueSoon: undefined,
  pastDue: undefined,
  borrowingBlocked: undefined,
  status: undefined
};

const cardContextObject = async (response) => {
  if (!response) {
    return {
      status: 500
    };
  }

  const { status } = response;
  if (status === 200) {
    return {
      ...await response.json(),
      status
    };
  }

  return {
    status
  };
};

const HelkaCardContextProvider = ({ isLoggedIn, children }) => {
  const [{
    barcode,
    loans,
    fees,
    onHold,
    dueSoon,
    pastDue,
    borrowingBlocked,
    status
  }, setHelkaCard] = useState(noHelkaCardState);

  const createHelkaCard = async (pinCode) => {
    const response = await cardContextObject(await putHelkaCard(pinCode));
    if (response.status === 200) {
      setHelkaCard(response);
    }
    return response.status;
  };

  useEffect(() => {
    if (isLoggedIn) {
      (async () => {
        setHelkaCard(await cardContextObject(await getHelkaCard()));
      })();
    } else {
      setHelkaCard(noHelkaCardState);
    }
  }, [isLoggedIn]);

  return (
    <HelkaCardContext.Provider value={{
      barcode,
      loans,
      fees,
      onHold,
      dueSoon,
      pastDue,
      borrowingBlocked,
      status,
      createHelkaCard
    }}
    >
      {children}
    </HelkaCardContext.Provider>
  );
};

HelkaCardContextProvider.propTypes = {
  isLoggedIn: bool.isRequired,
  children: oneOfType([element, arrayOf(element)]).isRequired
};

export default HelkaCardContextProvider;

export const useHelkaCard = () => useContext(HelkaCardContext);
