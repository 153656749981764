import { getConfigFromBackend } from '../api';
import {
  BASE_URL,
  getRootAttribute,
  readRequiredAttrFromDom,
  OBAR_HEADER_ROOT_ELEMENT_ID,
  OBAR_FEEDBACK_ROOT_ELEMENT_ID
} from './dom-config';

const getConfig = () => {
  const jwtToken = readRequiredAttrFromDom('data-jwt-token');
  const appName = readRequiredAttrFromDom('data-app-name');
  const contentId = getRootAttribute('data-content-id');
  const components = getRootAttribute('data-components');
  return getConfigFromBackend(appName, jwtToken).then(config => ({
    ...config,
    baseUrl: BASE_URL,
    obarRootElementName: config?.feedbackOnly ? OBAR_FEEDBACK_ROOT_ELEMENT_ID : OBAR_HEADER_ROOT_ELEMENT_ID,
    contentId,
    jwtToken,
    appName,
    components: components ? components.split(',') : null
  }));
};

export default getConfig;
