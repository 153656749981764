import { arrayOf, string } from 'prop-types';
import React from 'react';
import FooterColumn from '../../FooterColumn';
import FooterLinks from '../../FooterLinks';
import ContactInfo from '../ContactInfo';
import ServiceName from '../ServiceName';

import styles from '../footer.css';
import useTranslation from '../../../hooks/useTranslation';

const TabletLayout = ({ items }) => {
  const { t } = useTranslation();
  const middleIndex = Math.ceil(items.length / 2);
  const firstColumnItems = items.slice(0, middleIndex);
  const secondColumnItems = items.slice(middleIndex);

  return (
    <>
      <div className={styles.row}>
        <ServiceName />
      </div>
      <div className={styles.row}>
        <FooterColumn className={styles.noMargin}>
          <FooterLinks
            translate={t}
            items={firstColumnItems}
          />
        </FooterColumn>
        <FooterColumn className={styles.noMargin}>
          <FooterLinks
            translate={t}
            items={secondColumnItems}
          />
        </FooterColumn>
      </div>
      <div className={styles.row}>
        <FooterColumn>
          <div className="logo-block">
            <div className="logo-block__content">
              <ContactInfo />
            </div>
          </div>
        </FooterColumn>
        <FooterColumn>
          <div className={`l-footer__copyright ${styles.copyright}`}>
            <div>
              <p>{t('footer.copyright')}</p>
            </div>
          </div>
        </FooterColumn>
      </div>
    </>
  );
};

TabletLayout.propTypes = {
  items: arrayOf(string).isRequired
};

export default TabletLayout;
