import React from 'react';
import { string } from 'prop-types';
import {
  appConfigsType, userRoleTypeValues, userType
} from '../../constants/types';
import QuickLinks from '../QuickLinks';

import styles from './navigation.css';
import { trackNavigation } from '../../tracking';
import ExternalAppLink from '../ExternalAppLink';
import useTranslation from '../../hooks/useTranslation';

const getNavUrls = (config) => {
  if (!config || !config.navigation) {
    return [];
  }

  return config.navigation.urls
    ? Object.values(config.navigation.urls)
    : [config.navigation.url];
};

const isAbsoluteURL = url => url.startsWith('http');

const getNavLinks = (appName, t, lang, appConfigs) => {
  const navigationUrl = config => (
    (config.navigation.urls && config.navigation.urls[lang])
      ? config.navigation.urls[lang]
      : config.navigation.url);

  const { href, pathname } = window.location;
  const currentAppFromHref = Object.entries(appConfigs)
    .map(([app, config]) => ({
      app,
      urls: getNavUrls(config)
    }))
    .find(app => app.urls.some(url =>
      (isAbsoluteURL(url) ? href.startsWith(url) : pathname.startsWith(url))));

  const currentAppName = currentAppFromHref ? currentAppFromHref.app : appName;

  const onClick = app => trackNavigation(app);

  return Object.entries(appConfigs).map(([app, config]) => config && (
    <li key={app + lang}>
      <a
        href={navigationUrl(config)}
        className={app === currentAppName ? 'active active-trail' : ''}
        aria-current={app === currentAppName ? 'page' : ''}
        onClick={() => onClick(app)}
      >
        {t(`apps.${app}.navigation`)}
      </a>
    </li>
  ));
};

const Navigation = ({
  appName, appConfigs, user
}) => {
  const { t, lang } = useTranslation();

  return (
    <nav aria-label={t('header.mainNavigation')} className={`quick-menu ${styles.quickMenu}`}>
      <ul className={`menu ${styles.navigation} `}>
        {getNavLinks(appName, t, lang, appConfigs)}
        <li className={styles.divider} />
        <li>
          <ExternalAppLink appName="sisu" className={styles.externalLink} />
        </li>
        {user?.roles?.includes(userRoleTypeValues.TEACHER) && (
        <li>
          <ExternalAppLink appName="teachersFlamma" className={styles.externalLink} />
        </li>
        )}
        <li className={styles.divider} />
        <li>
          <QuickLinks />
        </li>
      </ul>
    </nav>
  );
};

Navigation.propTypes = {
  appName: string.isRequired,
  appConfigs: appConfigsType.isRequired,
  user: userType
};

export default Navigation;
