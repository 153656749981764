import React, { useState, useEffect, useRef } from 'react';
import { string, bool, func } from 'prop-types';

import AuthControls from '../AuthControls';
import Navigation from '../Navigation';
import LanguageSelector from '../LanguageSelector';
import Actions from '../Actions';
import MobileActions from '../Actions/mobileActions';
import Announcements from '../Announcements';
import { getNotifications, setNotificationRead } from '../../util';
import { LARGE_TABLET_MEDIA } from '../../constants';
import {
  configType,
  appConfigsType,
  userType,
  notificationsInfoType,
  helkaCardButtonInfoType,
  searchButtonInfoType,
  languageSelectEndpointType
} from '../../constants/types';
import OverlayContext from '../../context/OverlayContext';
import '../../styles/global';

import styles from './app.css';
import { trackObarLinkClick, trackMeceNotificationRead } from '../../tracking';
import OverlayContainer from '../OverlayContainer';
import useTranslation from '../../hooks/useTranslation';
import useFocusTrap from '../../hooks/useFocusTrap';

const isMobileScreenSize = () => (window.matchMedia(LARGE_TABLET_MEDIA).matches);
const RESIZE_LISTENER = 'resize';
const NOTIFICATION_FETCH_INTERVAL = 60000;

const MiddleRow = ({
  apps,
  isMobileMenuOpen,
  isMobileQuickLinksOpen,
  toggleMobileMenu,
  toggleMobileQuickLinks,
  notificationsButtonInfo,
  helkaCardButtonInfo,
  searchButtonInfo,
  user,
  isMobileDevice,
  closeAndFocusToToggle
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.headerMiddleRow}>
      <div className={styles.logoContainer}>
        <div className="logo" aria-hidden="true" />
        <div className={`logo__sitename ${styles.name}`}>
          {t('header.name')}
          <div className={styles.url}>{t('header.url')}</div>
        </div>
      </div>
      <div className={styles.actionContainer}>
        {isMobileDevice
          ? (
            <MobileActions
              isLoggedIn={!!user}
              appConfigs={apps}
              isMenuOpen={isMobileMenuOpen}
              isQuickLinksOpen={isMobileQuickLinksOpen}
              toggleMobileMenu={toggleMobileMenu}
              handleQuickLinksClick={toggleMobileQuickLinks}
              notificationsInfo={notificationsButtonInfo}
              helkaCardButtonInfo={helkaCardButtonInfo}
              searchButtonInfo={searchButtonInfo}
              user={user}
              closeAndFocusToToggle={closeAndFocusToToggle}
            />
          ) : (
            <Actions
              isLoggedIn={!!user}
              notificationsInfo={notificationsButtonInfo}
              helkaCardButtonInfo={helkaCardButtonInfo}
              searchButtonInfo={searchButtonInfo}
            />
          )}
      </div>
    </div>
  );
};

MiddleRow.propTypes = {
  apps: appConfigsType.isRequired,
  user: userType,
  isMobileMenuOpen: bool,
  isMobileQuickLinksOpen: bool,
  toggleMobileMenu: func.isRequired,
  toggleMobileQuickLinks: func.isRequired,
  notificationsButtonInfo: notificationsInfoType.isRequired,
  helkaCardButtonInfo: helkaCardButtonInfoType.isRequired,
  searchButtonInfo: searchButtonInfoType.isRequired,
  isMobileDevice: bool,
  closeAndFocusToToggle: func.isRequired
};

const TopRow = ({
  apps,
  user,
  isMobileMenuOpen,
  languageSelectEndpoints,
  loginEndpoint,
  logoutEndpoint
}) => {
  const { t } = useTranslation();
  const onHelsinkiFiClick = () => trackObarLinkClick('helsinki.fi');
  const onFlammaClick = () => trackObarLinkClick('flamma');

  return (
    <div className={`${styles.headerTopRow} ${isMobileMenuOpen ? styles.mobileHidden : ''}`}>
      <div className={styles.topRowLeft}>
        <a id="university-link" href={t('links.university.url')} onClick={onHelsinkiFiClick} onAuxClick={onHelsinkiFiClick}>{t('links.university.name')}</a>
        <a href={t('links.flamma.url')} onClick={onFlammaClick} onAuxClick={onFlammaClick}>{t('links.flamma.name')}</a>
      </div>
      <div className={styles.topRowRight}>
        <LanguageSelector
          apps={apps}
          languageSelectEndpoints={languageSelectEndpoints}
        />
        <AuthControls
          user={user}
          loginEndpoint={loginEndpoint}
          logoutEndpoint={logoutEndpoint}
        />
      </div>
    </div>
  );
};

TopRow.propTypes = {
  apps: appConfigsType.isRequired,
  user: userType,
  isMobileMenuOpen: bool,
  languageSelectEndpoints: languageSelectEndpointType,
  loginEndpoint: string.isRequired,
  logoutEndpoint: string.isRequired
};

const App = ({
  config
}) => {
  const {
    appName, apps, user, mece, contentId, loginEndpoint, logoutEndpoint, languageSelectEndpoints, showSearchButton, studiesBaseUrl, components
  } = config;
  const { t, lang } = useTranslation();

  const [showNotifications, setShowNotifications] = useState(mece !== undefined && mece !== null);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isMobileQuickLinksOpen, setMobileQuickLinksOpen] = useState(false);
  const [isMobileDevice, setMobileDevice] = useState(isMobileScreenSize());
  const [activeOverlay, setActiveOverlay] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);
  const [initialized, setInitialized] = useState(false);

  const updateMobileState = () => setMobileDevice(isMobileScreenSize());
  const toggleMobileMenu = () => setMobileMenuOpen(!isMobileMenuOpen);
  const toggleMobileQuickLinks = () => setMobileQuickLinksOpen(!isMobileQuickLinksOpen);

  // inserted conditionally: if service has already set its Cookiebot, let it be used instead
  useEffect(() => {
    let obarCBot;
    if (!document.getElementById('Cookiebot')) {
      obarCBot = document.createElement('script');
      obarCBot.setAttribute('id', 'Cookiebot');
      obarCBot.setAttribute('src', 'https://consent.cookiebot.com/uc.js');
      obarCBot.setAttribute('data-cbid', 'e422c4ee-0ebe-400c-b22b-9c74b6faeac3');
      obarCBot.setAttribute('data-blockingmode', 'auto');
      obarCBot.setAttribute('type', 'text/javascript');
      obarCBot.setAttribute('data-culture', lang);
      document.head.insertBefore(obarCBot, document.head.getElementsByTagName('meta')[0]);
    }
    return () => {
      if (obarCBot) {
        document.head.removeChild(obarCBot);
      }
    };
  }, [lang]);

  useEffect(() => {
    window.addEventListener(RESIZE_LISTENER, updateMobileState);
    return () => window.removeEventListener(RESIZE_LISTENER, updateMobileState);
  }, []);

  useEffect(() => {
    let errorCounter = 0;
    if (showNotifications) {
      const updateNotifications = () =>
        getNotifications(mece.domain, mece.jwtToken)
          .then((data = []) => {
            setNotifications(data);
            setUnreadNotificationCount(data.filter(notification => !notification.read).length);
          })
          .catch((error) => {
            // If Mece token expires or Mece IP-blocks us, we stop calling it.
            errorCounter += 1;
            if (error.status === 401 || errorCounter > 4) {
              setShowNotifications(false);
            }
          });

      if (!initialized) {
        setInitialized(true);
        updateNotifications();
        return () => {};
      }

      const interval = setInterval(updateNotifications, NOTIFICATION_FETCH_INTERVAL);

      return () => clearTimeout(interval);
    }
    return () => {};
  }, [showNotifications, mece, initialized, notifications, setNotifications, setUnreadNotificationCount]);

  const markRead = id =>
    setNotificationRead(mece.domain, mece.jwtToken, id).then(() => {
      trackMeceNotificationRead();
      setInitialized(false);
    });

  const SkipToContent = () => {
    if (contentId) {
      return <a id="skip-to-content" href={`#${contentId}`} className={styles.skipToContent}>{t('header.skipToContent')}</a>;
    }
    return null;
  };

  const notificationsReturnRef = React.useRef();
  const notificationsButtonInfo = { showNotifications, unreadNotificationCount, notificationsReturnRef };
  const notificationsOverlayInfo = { notifications, notificationsReturnRef, markRead };
  const helkaCardReturnRef = React.useRef();
  const helkaCardButtonInfo = { helkaCardReturnRef };
  const helkaCardOverlayInfo = { helkaCardReturnRef, isLoggedIn: !!user };
  const searchReturnRef = React.useRef();
  const searchButtonInfo = { showSearchButton, searchReturnRef };
  const searchOverlayInfo = { studiesBaseUrl, searchReturnRef };

  const headerRef = useRef();

  const closeAndFocusToToggle = () => {
    if (isMobileMenuOpen) {
      toggleMobileMenu();
      window.setTimeout(() => {
        notificationsReturnRef?.current?.focus();
      }, 100);
    }
  };

  const keepFocusOnMenu = (event) => {
    if (event.target && event.target.tabIndex >= 0) {
      event.target.focus();
    } else if (isMobileMenuOpen) {
      notificationsReturnRef?.current?.focus();
    }
  };

  const {
    startClickedTimeout, closeIfFocusOutside
  } = useFocusTrap(closeAndFocusToToggle, headerRef, isMobileMenuOpen, isMobileQuickLinksOpen);

  return (
    <OverlayContext.Provider
      value={{ activeOverlay, setActiveOverlay }}
    >
      <span className={styles.header}>
        {!components && (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions
          <div
            onBlur={closeIfFocusOutside}
            onMouseDownCapture={startClickedTimeout}
            onClick={keepFocusOnMenu}
            className={styles.headerContent}
            ref={headerRef}
          >
            <SkipToContent />
            <TopRow
              apps={apps}
              languageSelectEndpoints={languageSelectEndpoints}
              loginEndpoint={loginEndpoint}
              logoutEndpoint={logoutEndpoint}
              user={user}
              isMobileMenuOpen={isMobileMenuOpen}
            />
            <MiddleRow
              user={user}
              helkaCardButtonInfo={helkaCardButtonInfo}
              apps={apps}
              isMobileMenuOpen={isMobileMenuOpen}
              isMobileQuickLinksOpen={isMobileQuickLinksOpen}
              notificationsButtonInfo={notificationsButtonInfo}
              searchButtonInfo={searchButtonInfo}
              toggleMobileMenu={toggleMobileMenu}
              toggleMobileQuickLinks={toggleMobileQuickLinks}
              isMobileDevice={isMobileDevice}
              closeAndFocusToToggle={closeAndFocusToToggle}
            />
            <div className={styles.navigationContainer}>
              <Navigation
                appName={appName}
                appConfigs={apps}
                user={user}
              />
            </div>
          </div>
        )}
        <OverlayContainer
          notificationsOverlayInfo={notificationsOverlayInfo}
          helkaCardOverlayInfo={helkaCardOverlayInfo}
          searchOverlayInfo={searchOverlayInfo}
        />
        {(!components || components.includes('announcements')) && (
          <Announcements langCode={lang} />
        )}
      </span>
    </OverlayContext.Provider>
  );
};

App.propTypes = {
  config: configType.isRequired
};

export default App;
