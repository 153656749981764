import React from 'react';
import { arrayOf, string } from 'prop-types';
import sanitize from 'sanitize-html';

/**
 * Helper component for rendering raw HTML. The given HTML is sanitized with sanitize-html
 * https://github.com/apostrophecms/sanitize-html
 */
const SanitizedHtml = ({ html, allowedTags = ['p', 'ul', 'li'] }) => {
  const sanitizedHtml = sanitize(html, {
    allowedTags,
    allowedAttributes: {} // No attributes are allowed
  });

  // eslint-disable-next-line
  return <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
};

SanitizedHtml.propTypes = {
  html: string.isRequired,
  allowedTags: arrayOf(string)
};

export default SanitizedHtml;
