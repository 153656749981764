import React from 'react';
import {
  oneOfType, arrayOf, node, string
} from 'prop-types';

const FooterColumn = ({ children, className }) => (
  <div className={`l-footer__column ${className}`}>
    {children}
  </div>
);

FooterColumn.defaultProps = {
  className: ''
};

FooterColumn.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  className: string
};

export default FooterColumn;
