import React, {
  useState, useEffect, useContext, useCallback
} from 'react';
import {
  arrayOf, element, oneOfType
} from 'prop-types';

import ThemeContext, { THEMES } from '.';

const DARK_COLOR_SCHEME = '(prefers-color-scheme: dark)';

const LISTENER_EVENT = 'change';

const IS_SAMSUNG_BROWSER = !!navigator.userAgent.match(/samsung/i);

const ThemeContextProvider = ({ children }) => {
  const [theme, setTheme] = useState(THEMES.LIGHT);

  const handleModeChange = useCallback((darkMode) => {
    const newTheme = darkMode ? THEMES.DARK : THEMES.LIGHT;
    if (newTheme !== theme) {
      setTheme(newTheme);
    }
  }, [theme, setTheme]);

  useEffect(() => {
    // Set initial mode
    const isDarkMode = window.matchMedia(DARK_COLOR_SCHEME).matches;
    handleModeChange(isDarkMode);

    window.matchMedia(DARK_COLOR_SCHEME)
      .addEventListener(LISTENER_EVENT, (event) => {
        handleModeChange(event.matches);
      });
    return () => {
      window.matchMedia(DARK_COLOR_SCHEME).removeEventListener(LISTENER_EVENT, () => {
      });
    };
  }, [handleModeChange, theme]);

  return (
    <ThemeContext.Provider value={{ theme, isSamsungBrowser: IS_SAMSUNG_BROWSER }}>
      {children}
    </ThemeContext.Provider>
  );
};

ThemeContextProvider.propTypes = {
  children: oneOfType([element, arrayOf(element)]).isRequired
};

export default ThemeContextProvider;

export const useTheme = () => useContext(ThemeContext);
