import React, { useState } from 'react';
import { string } from 'prop-types';
import styles from './searchOverlay.css';
import { trackObarLinkClick, trackSearch } from '../../tracking';
import Arrow from '../Arrow';
import ExternalLink from '../ExternalLink';
import useTranslation from '../../hooks/useTranslation';

const SearchOverlay = ({ studiesBaseUrl }) => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');

  const doSearch = (path) => {
    trackSearch(searchText);
    window.location.replace(`${studiesBaseUrl}/${path}?searchText=${encodeURIComponent(searchText)}`);
  };

  const onSubmit = (event, path) => {
    event.preventDefault();
    doSearch(path);
  };

  const ExternalLinkRow = ({ name }) => (
    <ExternalLink
      href={t(`search.${name}Link`)}
      onClick={() => trackObarLinkClick(name)}
      onAuxClick={() => trackObarLinkClick(name)}
      iconClass={styles.arrowContainer}
      iconColor="white"
      boxed
      className={styles.linkContainer}
      iconFirst
    >
      <div className={styles.textContainer}>
        <h3 className={styles.linkTexts}>{t(`search.${name}`)}</h3>
        <span className={styles.linkTexts}>{t(`search.${name}Description`)}</span>
      </div>
    </ExternalLink>
  );

  ExternalLinkRow.propTypes = {
    name: string.isRequired
  };

  return (
    <div className={styles.overlayContent}>
      <form role="search">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="obar-search-text" className="sr-only">{t('search.inputLabel')}</label>
        <input
          id="obar-search-text"
          placeholder={t('search.placeholder')}
          value={searchText}
          type="search"
          onChange={event => setSearchText(event.target.value)}
        />

        <input
          id="obar-search-submit"
          type="submit"
          className="button"
          onClick={e => onSubmit(e, t('search.resultPageUrlPath'))}
          value={t('search.buttonText')}
        />
      </form>
      <div>
        <h2>{t('search.specificSearch')}</h2>
        <a
          href={`${studiesBaseUrl}${t('search.coursesSearchPath')}`}
          onClick={() => trackObarLinkClick('coursesSearch')}
          onAuxClick={() => trackObarLinkClick('coursesSearch')}
          className={styles.linkContainer}
        >
          <Arrow color="white" containerClassName={styles.arrowContainer} boxed />
          <div className={styles.textContainer}>
            <h3 className={styles.linkTexts}>{t('search.coursesSearch')}</h3>
            <span className={styles.linkTexts}>{t('search.coursesSearchDescription')}</span>
          </div>
        </a>
        <h2>{t('search.otherSearchTools')}</h2>
        <ExternalLinkRow name="searchProgrammes" />
        <ExternalLinkRow name="peopleFinder" />
        <ExternalLinkRow name="helkaSearch" />
      </div>
    </div>
  );
};

SearchOverlay.propTypes = {
  studiesBaseUrl: string.isRequired
};

export default SearchOverlay;
