import React, { useState } from 'react';
import {
  number, bool, oneOf, string, shape
} from 'prop-types';
import Barcode from 'react-barcode';
import ExternalLink from '../ExternalLink';
import HelkaCardContextProvider, { useHelkaCard } from '../../context/HelkaCardContext/HelkaCardContextProvider';
import ThemeContextProvider, { useTheme } from '../../context/ThemeContext/ThemeContextProvider';
import { THEMES } from '../../context/ThemeContext';
import { hyphenateText } from '../../util';

import CreateHelkaCard from './CreateHelkaCard';
import HelkaLogoBlack from '../../images/helka_logo_black_transparent.png';
import HelkaLogoWhite from '../../images/helka_logo_white_transparent.png';
import styles from './helkaCardOverlay.css';
import useTranslation from '../../hooks/useTranslation';

// KEEP IN SYNC WITH
//   backend/src/routes/helka.js
//   backend/src/services/helka.js
// Easiest is to check backend/src/test/spec/routes/helka.test.js
// For now
//   200 is Success
//   401 there is no eppn in session, with which request was made
//   404 user is not in Helka
//   500 when Helka request returns an error
//   502 when Helka responds with something else than that user does not exist
const HELKA_CARD_RESPONSE_STATUS = {
  OK: 200,
  USER_NOT_FOUND: 404
};

const HELKA_BARCODE_FORMAT = 'CODE39';

const feesInEuros = fees => new Intl.NumberFormat('fi-FI', { style: 'currency', currency: 'EUR' }).format(fees);

const HelkaLogo = ({ className = styles.helkaLogo, alt = 'Helka logo' }) => {
  const { theme } = useTheme();

  return (<img className={className} src={theme === THEMES.DARK ? HelkaLogoWhite : HelkaLogoBlack} alt={alt} />);
};

const HelkaCard = ({ barcode, sideMargin }) => {
  const { t } = useTranslation();
  const { isSamsungBrowser } = useTheme();
  const barcodeNightModeFix = isSamsungBrowser ? styles.barcodeNightModeFix : '';

  return (
    <div className={styles.helkaCard}>
      <div className={`${styles.barcodeContainer} ${barcodeNightModeFix}`}>
        <HelkaLogo />
        <div aria-live="polite" className={styles.showCardInstructions}>
          {t('helkaCardOverlay.displayCardInstructions')}
        </div>
        <div className="sr-only">{t('helkaCardOverlay.barcode')}</div>
        <Barcode
          value={barcode}
          // Note: react-barcode resolves all font props to a one-line CSS font declaration in an inline
          // style attribute in the generated svg element
          font={'"Open Sans", Helvetica, Arial, sans-serif'}
          fontSize={14} // always px, has to be a number
          fontOptions="600" // these are applied to the beginning
          width={1.65}
          height={84} // px
          format={HELKA_BARCODE_FORMAT}
          marginTop={12} // px
          marginBottom={7} // px
          marginLeft={sideMargin}
          marginRight={sideMargin}
        />
      </div>
    </div>
  );
};

const errorNotification = message => (
  <div className={styles.errorNotification}>
    <span className={`icon--info-stroke icon-2x ${styles.alertIcon}`} />
    <div className={styles.errorMessage}>{message}</div>
  </div>
);

const userNotFoundNotification = t => errorNotification(t('helkaCardOverlay.userNotFound'));

const helkaLoginButton = t => (
  <ExternalLink
    className={`button ${styles.externalLinkButton}`}
    href={t('helkaCardOverlay.helkaLoginUrl')}
    iconClass={styles.externalLinkIcon}
  >
    {t('helkaCardOverlay.toHelka')}
  </ExternalLink>
);

const Links = ({ linkUrl, linkContent }) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const iconColor = theme === THEMES.DARK ? 'white' : 'blue';

  return (
    <>
      <ExternalLink className={styles.overlayLink} href={linkUrl} iconColor={iconColor}>{linkContent}</ExternalLink>
      <ExternalLink className={styles.overlayLink} href={t('helkaCardOverlay.libraryUrl')} iconColor={iconColor}>{t('helkaCardOverlay.toLibraryPages')}</ExternalLink>
    </>
  );
};

const MyAccount = ({ helkaCard }) => {
  const { t, lang } = useTranslation();
  const { theme } = useTheme();
  const iconColor = theme === THEMES.DARK ? 'white' : 'blue';

  const rows = [
    {
      id: 'loans',
      text: t('helkaCardOverlay.loans'),
      value: helkaCard.loans
    },
    {
      id: 'pickup',
      text: t('helkaCardOverlay.requestsAvailableForPickup'),
      value: helkaCard.onHold
    },
    {
      id: 'dueSoon',
      text: t('helkaCardOverlay.loansDueSoon'),
      value: helkaCard.dueSoon
    },
    {
      id: 'pastDue',
      text: t('helkaCardOverlay.loansPastDue'),
      value: helkaCard.pastDue
    },
    {
      id: 'fees',
      text: t('helkaCardOverlay.fees'),
      value: feesInEuros(helkaCard.fees)
    },
    {
      id: 'borrowingBlocked',
      text: t('helkaCardOverlay.borrowingBlocked'),
      value: helkaCard.borrowingBlocked ? t('yes') : t('no')
    }
  ];

  return (
    <section aria-label={t('helkaCardOverlay.myAccount')} className={styles.myAccount}>
      <ExternalLink className={styles.largeLink} href={`https://helka.helsinki.fi/discovery/account?vid=358UOH_INST:VU1&section=overview&lang=${lang}`} iconColor={iconColor}>{t('helkaCardOverlay.myAccount')}</ExternalLink>
      <div className={styles.myAccountTable}>
        {rows.map(row => (
          <div key={`${row.id}-${row.value}`} className={styles.tableRow}>
            <div className={styles.tableElementLeft}>
              {row.text}
            </div>
            <div className={styles.tableElementRight}>
              {row.value}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

const view = (
  {
    firstColumn,
    secondColumn,
    links,
    t,
    leftColumnClassNames = styles.leftColumn,
    leftColumnLinksClassNames = styles.links,
    rightColumnClassNames = styles.rightColumn,
    rightColumnLinksClassNames = styles.mobileLinks,
    fullExplanation = false
  }
) => (
  <div className={styles.overlayContent}>
    <div className={leftColumnClassNames}>
      {firstColumn}
      <div className={leftColumnLinksClassNames}>
        {links}
      </div>
    </div>
    <div className={rightColumnClassNames}>
      <div className={styles.explanation}>
        <div className={styles.subTitle}><h3 id="helkaCardLeftSubTitle">{t('helkaCard')}</h3></div>
        {fullExplanation
          ? t('helkaCardOverlay.explanationWithUserLiabilityNotice')
          : t('helkaCardOverlay.explanation')}
      </div>
      {secondColumn}
      <div className={rightColumnLinksClassNames}>
        {links}
      </div>
    </div>
  </div>
);

const helkaCardCreatedView = (barcode, sideMargin, helkaCardCreatedMessage, t) =>
  view(
    {
      firstColumn:
      (
        <>
          <div className={styles.subTitle}><h3 id="helkaCardSubTitle">{t('helkaCard')}</h3></div>
          <div className={styles.helkaCardCreatedExplanation}>{t('helkaCardOverlay.explanationWithUserLiabilityNotice')}</div>
          <div aria-live="assertive" className={styles.helkaCardCreatedNotification}>
            <div aria-hidden="true" className={styles.successIconContainer}>
              <span className={`icon--done ${styles.successIconCheck}`} />
            </div>
            <div className={styles.helkaCardCreatedMessage}>
              {helkaCardCreatedMessage}
            </div>
          </div>
          <HelkaCard barcode={barcode} sideMargin={sideMargin} t={t} />
        </>
      ),
      links: <Links linkUrl={t('helkaCardOverlay.cardAndLibraryRightsUrl')} linkContent={t('helkaCardOverlay.cardAndLibraryRights')} />,
      t,
      leftColumnClassNames: `${styles.leftColumn} ${styles.helkaCardCreatedLeftColumn}`,
      rightColumnClassNames: styles.helkaCardCreatedRightColumn,
      leftColumnLinksClassNames: styles.helkaCardCreatedMobileLinks,
      fullExplanation: true
    }
  );

const helkaCardView = (helkaCard, sideMargin, t) => (
  view(
    {
      firstColumn: (
        <HelkaCard barcode={helkaCard.barcode} sideMargin={sideMargin} t={t} />
      ),
      secondColumn: (
        <MyAccount helkaCard={helkaCard} />
      ),
      links: <Links linkUrl={t('helkaCardOverlay.forgotPinCodeUrl')} linkContent={t('helkaCardOverlay.forgotPinCode')} />,
      t,
      leftColumnClassNames: `${styles.leftColumn} ${styles.helkaCardLeftColumn}`,
      rightColumnClassNames: `${styles.explanationAndLinks} ${styles.helkaCardRightColumn}`,
      rightColumnLinksClassNames: styles.mobileLinks,
      fullExplanation: true
    }
  )
);

const getHelkaCardView = (setShowCreateHelkaCardView, t, lang) => (
  view(
    {
      firstColumn:
        (
          <div className={styles.getCard}>
            <div className={styles.getCardInstructions}>
              <HelkaLogo />
              <span aria-hidden="true">{hyphenateText(t('helkaCardOverlay.getCardInstructions'), lang)}</span>
              <span className="sr-only">{t('helkaCardOverlay.getCardInstructions')}</span>
            </div>
            <div className={styles.getHelkaCardButtonContainer}>
              <button
                type="button"
                className={`button ${styles.button}`}
                onClick={() => setShowCreateHelkaCardView(true)}
                aria-haspopup="true"
              >
                {t('helkaCardOverlay.getCard')}
              </button>
            </div>
          </div>
        ),
      links: <Links linkUrl={t('helkaCardOverlay.cardAndLibraryRightsUrl')} linkContent={t('helkaCardOverlay.cardAndLibraryRights')} />,
      t,
      leftColumnClassNames: styles.leftColumnHideLinks,
      rightColumnClassNames: styles.explanationAndLinks,
      rightColumnLinksClassNames: styles.linksShowBoth
    }
  )
);

const registerToHelkaView = t => (
  view(
    {
      firstColumn:
        (
          <div className={styles.registerToHelka}>
            <HelkaLogo />
            {userNotFoundNotification(t)}
            <div className={styles.userNotFoundGetCardInstructions}>{t('helkaCardOverlay.userNotFoundGetCardInstructions')}</div>
            <div className={styles.helkaLoginButtonContainer}>
              {helkaLoginButton(t)}
            </div>
          </div>
        ),
      links: <Links linkUrl={t('helkaCardOverlay.cardAndLibraryRightsUrl')} linkContent={t('helkaCardOverlay.cardAndLibraryRights')} />,
      t,
      leftColumnClassNames: styles.leftColumnHideLinks,
      rightColumnClassNames: styles.explanationAndLinks,
      rightColumnLinksClassNames: styles.linksShowBoth
    }
  )
);

const errorView = (status, t) => (
  <div className={styles.overlayContent}>
    {errorNotification(`${t('helkaCardOverlay.error')} ${status}`)}
  </div>
);

export const Overlay = () => {
  const { t, lang } = useTranslation();
  const helkaCard = useHelkaCard();
  const { barcode, status } = helkaCard;
  const [showCreateHelkaCardView, setShowCreateHelkaCardView] = useState(false);
  const [helkaCardCreatedMessage, setHelkaCardCreatedMessage] = useState(null);

  if (showCreateHelkaCardView) {
    return (
      <CreateHelkaCard
        setShowCreateHelkaCardView={setShowCreateHelkaCardView}
        setHelkaCardCreatedMessage={setHelkaCardCreatedMessage}
      />
    );
  }
  if (barcode && helkaCardCreatedMessage) {
    return helkaCardCreatedView(barcode, 20, helkaCardCreatedMessage, t);
  }
  if (barcode) {
    return helkaCardView(helkaCard, 20, t);
  }
  if (!barcode && status === HELKA_CARD_RESPONSE_STATUS.OK) {
    return getHelkaCardView(setShowCreateHelkaCardView, t, lang);
  }
  if (status === HELKA_CARD_RESPONSE_STATUS.USER_NOT_FOUND) {
    return registerToHelkaView(t);
  }
  if (!status && !barcode) {
    // Helka response is still on the way
    return null;
  }
  return errorView(status, t);
};

const HelkaCardOverlayWrapper = ({ isLoggedIn = false, theme = THEMES.LIGHT, isSamsungBrowser = false }) => (
  <ThemeContextProvider theme={theme} isSamsungBrowser={isSamsungBrowser}>
    <HelkaCardContextProvider isLoggedIn={isLoggedIn}>
      <Overlay />
    </HelkaCardContextProvider>
  </ThemeContextProvider>
);

HelkaCard.propTypes = {
  barcode: string.isRequired,
  sideMargin: number.isRequired
};

Links.propTypes = {
  linkUrl: string.isRequired,
  linkContent: string.isRequired
};

MyAccount.propTypes = {
  helkaCard: shape({
    loans: number,
    fees: number,
    onHold: number,
    dueSoon: number,
    pastDue: number,
    borrowingBlocked: bool
  })
};

HelkaCardOverlayWrapper.propTypes = {
  isLoggedIn: bool,
  theme: oneOf([THEMES.LIGHT, THEMES.DARK]),
  isSamsungBrowser: bool
};

export default HelkaCardOverlayWrapper;
