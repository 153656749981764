import React from 'react';
import { useDeviceSize } from '../../../context/DeviceSizeContext/DeviceSizeContextProvider';
import { deviceSizes } from '../../../constants';
import useTranslation from '../../../hooks/useTranslation';

const ContactInfo = () => {
  const { t } = useTranslation();
  const { deviceSize } = useDeviceSize();
  return (
    <>
      <div className="logo-block__sitename">{t('footer.address.name')}</div>
      <p>
        {t('footer.address.line1')}
        <br />
        {t('footer.address.line2')}
      </p>
      <p>
        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
        {t('footer.switchboard')}: {deviceSize === deviceSizes.SMALL_TABLET ? (
          <a className="is-tel" href={`tel:${t('footer.telephoneUnformatted')}`}>{t('footer.telephone')}</a>
        ) : t('footer.telephone')}
        <br />
        {/* eslint-disable-next-line */}
        ({t('footer.mobileCallCharge')})
      </p>
    </>
  );
};

export default ContactInfo;
