import Hypher from 'hypher';
import hyphenationFi from 'hyphenation.fi';
import hyphenationSv from 'hyphenation.sv';
import hyphenationEn from 'hyphenation.en-gb';
import { availableLanguages } from '../constants';
import mockNotifications from './mock-notifications.json';

const HYPHENATOR = {
  fi: new Hypher(hyphenationFi),
  sv: new Hypher(hyphenationSv),
  en: new Hypher(hyphenationEn)
};

export const hyphenateText = (text, lang = 'fi') => HYPHENATOR[lang].hyphenateText(text);

export const transformLocalizedUrl = (currentUrl, currentLang, newLang, apps) => {
  const localizedBaseUrls = Object.values(apps)
    .filter(app => app && app.navigation && app.navigation.urls && typeof app.navigation.urls === 'object')
    .map(app => app.navigation.urls)
    .find(urls => urls[currentLang] && currentUrl.indexOf(urls[currentLang]) === 0);

  if (localizedBaseUrls && localizedBaseUrls[newLang]) {
    return localizedBaseUrls[newLang];
  }

  return currentUrl;
};

export const getTranslation = (strMap, lang = 'fi') => {
  if (strMap) {
    return strMap[lang]
      || strMap[availableLanguages[0]]
      || availableLanguages
        .filter(l => strMap[l])
        .map(l => strMap[l])
        .shift();
  }

  return null;
};

export const getServiceLinksAlphabetizer = (translate, lang) =>
  services => services.sort((f, s) => translate(`links.${f}.name`).localeCompare(translate(`links.${s}.name`), lang));

const getJson = async (path) => {
  const response = await fetch(path, {
    mode: 'cors',
    headers: {
      Accept: 'application/json'
    }
  });
  const isJson = response.headers.get('content-type')?.includes('application/json');
  if (response.ok && isJson) {
    return response.json();
  }
  if (response.ok) {
    return null;
  }

  throw response;
};

const getUrl = (domain, path, jwtToken) =>
  `${domain}${path}?token=${jwtToken}&host=obar`;

const LOCAL_MOCK_IMPLEMENTATION_NAME = 'localMock';

export const getNotifications = (domain, jwtToken, suppressErrors) => (domain === LOCAL_MOCK_IMPLEMENTATION_NAME
  ? Promise.resolve(mockNotifications)
  : getJson(getUrl(domain, '/mece/api/notifications', jwtToken), suppressErrors));

const setMockNotificationRead = (id) => {
  mockNotifications.forEach((notification) => {
    if (notification.id === id) {
      notification.read = true;
    }
  });
  return Promise.resolve();
};

export const setNotificationRead = (domain, jwtToken, id) => (domain === LOCAL_MOCK_IMPLEMENTATION_NAME
  ? setMockNotificationRead(id)
  : getJson(`${getUrl(domain, '/mece/api/notifications/markRead', jwtToken)}&id=${id}`));

export const mergeRefs = (...refs) => {
  const filteredRefs = refs.filter(Boolean);
  if (!filteredRefs.length) {
    return null;
  }
  if (filteredRefs.length === 1) {
    return filteredRefs[0];
  }
  return inst => filteredRefs.forEach((ref) => {
    if (typeof ref === 'function') {
      ref(inst);
    } else {
      ref.current = inst;
    }
  });
};
