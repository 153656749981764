import React from 'react';
import {
  string,
  arrayOf,
  bool
} from 'prop-types';

import { hyphenateText, getServiceLinksAlphabetizer } from '../../util';
import styles from './quickLinks.css';
import QuickLink from './QuickLink';
import useTranslation from '../../hooks/useTranslation';

const QuickLinkGroup = ({
  categoryName,
  services,
  wide
}) => {
  const { t, lang } = useTranslation();

  const alphabetizeServiceLinks = getServiceLinksAlphabetizer(t, lang);

  return (
    <>
      <h3 aria-label={t(categoryName)} className={styles.groupTitle}>
        {hyphenateText(t(categoryName))}
      </h3>
      <ul className={styles.linkGroup}>
        {
          alphabetizeServiceLinks(services).map(service =>
            <QuickLink wide={wide} service={service} key={service} />)
        }
      </ul>
    </>
  );
};

QuickLinkGroup.propTypes = {
  categoryName: string.isRequired,
  services: arrayOf(string).isRequired,
  wide: bool
};

export default QuickLinkGroup;
