const loadMatomoTagManagerScript = () => {
  /* eslint-disable */
  var _mtm = window._mtm = window._mtm || [];
  _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
  var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
  g.async=true; g.src='https://matomo.it.helsinki.fi/matomo/js/container_NMx6LTvF.js'; s.parentNode.insertBefore(g,s);
  /* eslint-enable */
};

/* eslint-disable no-underscore-dangle */

// Mappings for labels with differing name from internal service name
const navigationLabelByLink = {
  studies: 'frontpage',
  guide: 'instructions'
};

const servicesAndToolsLabelByLink = {
  searchForDegreeProgrammes: 'degreeProgrammes',
  peopleFinder: 'people',
  appointmentsToStudentServices: 'appointments'
};

const footerEventLabelByLink = {
  bulletin: 'bulletinArchive',
  dataProtectionStatement: 'dataProtection',
  contact: 'people',
  facilityAccessibilityStatement: 'facilities',
  accessibilityStatement: 'accessibility'
};

const navigationLinkToLabel = appName => navigationLabelByLink[appName] || appName;

const servicesAndToolsLinkToLabel = service => servicesAndToolsLabelByLink[service] || service;

const footerLinkToLabel = service => footerEventLabelByLink[service] || service;

export const initializeTracking = (config) => {
  if (config.matomoTracking !== 'true') {
    return;
  }

  if (!window._mtm) {
    loadMatomoTagManagerScript();
  }
};

export const trackEvent = (category, action, label) => {
  if (window._mtm) {
    window._mtm.push({
      event: action, category, label
    });
  }
};

export const trackLogin = () => trackEvent('obarLogin', 'login');

export const trackLogout = () => trackEvent('obarLogin', 'logout');

export const trackServicesAndToolsState = state => trackEvent('obarServicesAndTools', state);

export const trackServicesAndToolsClick = service => trackEvent('obarServicesAndTools', 'click', servicesAndToolsLinkToLabel(service));

export const trackNavigation = appName => trackEvent('obarNavigation', 'click', navigationLinkToLabel(appName));

export const trackLanguageChange = language => trackEvent('obarLanguage', 'changed', language);

export const trackMeceToggle = hasNotifications => trackEvent('obarMece', 'toggle', hasNotifications);

export const trackMeceNotificationRead = () => trackEvent('obarMece', 'read');

export const trackFooterLinkClick = service => trackEvent('obarFooter', 'click', footerLinkToLabel(service));

export const trackObarLinkClick = link => trackEvent('obarLinks', 'click', link);

export const trackSendFeedback = () => trackEvent('obarFeedback', 'send');

export const trackSearch = searchText => trackEvent('obarSearch', 'submit', searchText);
