import React from 'react';
import { bool } from 'prop-types';

import HelkaCardButton from '../HelkaCardButton';
import NotificationsButton from '../NotificationsButton';
import SearchButton from '../SearchButton';
import { notificationsInfoType, helkaCardButtonInfoType, searchButtonInfoType } from '../../constants/types';
import styles from './actions.css';

const Actions = ({
  isLoggedIn = false, helkaCardButtonInfo, notificationsInfo, searchButtonInfo
}) => {
  const { showNotifications, unreadNotificationCount, notificationsReturnRef } = notificationsInfo;
  const { helkaCardReturnRef } = helkaCardButtonInfo;
  const { showSearchButton, searchReturnRef } = searchButtonInfo;

  return (
    <div className={styles.desktopActions}>
      <div className={styles.actionContainer}>
        {showSearchButton && (
          <SearchButton searchReturnRef={searchReturnRef} />
        )}
      </div>
      {isLoggedIn && (
        <>
          <div className={styles.actionContainer}>
            <HelkaCardButton helkaCardReturnRef={helkaCardReturnRef} />
          </div>
          <div className={styles.actionContainer}>
            {showNotifications && (
              <NotificationsButton unreadNotificationCount={unreadNotificationCount} notificationsReturnRef={notificationsReturnRef} />
            )}
          </div>
        </>
      )}
    </div>
  );
};

Actions.propTypes = {
  isLoggedIn: bool,
  notificationsInfo: notificationsInfoType.isRequired,
  helkaCardButtonInfo: helkaCardButtonInfoType.isRequired,
  searchButtonInfo: searchButtonInfoType.isRequired
};

export default Actions;
