import React, { useEffect, useRef } from 'react';
import { bool, func, string } from 'prop-types';
import { format, parseISO } from 'date-fns';

import { DATE_TIME_FORMAT } from '../../../constants';
import { notificationType } from '../../../constants/types';
import ExternalLink from '../../ExternalLink';
import styles from './notification.css';
import useTranslation from '../../../hooks/useTranslation';

const Notification = ({
  notification, markRead, isFocus, lang
}) => {
  const { t } = useTranslation();
  const ref = useRef();

  useEffect(() => {
    if (isFocus) {
      ref.current.focus();
    }
  }, [isFocus]);

  const handleMarkRead = () => {
    if (!notification.read) {
      markRead(notification.id);
    }
  };

  let headingClasses = styles.heading;
  if (notification.read) {
    headingClasses += ` ${styles.isRead}`;
  }

  const headingId = `notification-heading-${notification.id}`;

  return (
    <div ref={ref} className={styles.container}>
      <div className={styles.content}>
        <div className={styles.header}>
          <h2 className={headingClasses} id={headingId}>{notification.heading}</h2>
          <span className={styles.date}>
            {format(parseISO(notification.received), DATE_TIME_FORMAT[lang])}
          </span>
        </div>
        <div className={styles.text}>
          {notification.message}
        </div>
        {notification.linkUrl && (
        <ExternalLink
          href={notification.linkUrl}
          iconColor="blue"
          className={styles.link}
          iconClass={styles.linkArrow}
          onClick={handleMarkRead}
          onAuxClick={handleMarkRead}
        >
          {notification.linkText || t('readMore')}
        </ExternalLink>
        )}
      </div>
      <div className={styles.readStatus}>
        <button
          aria-disabled={notification.read || undefined}
          type="button"
          className={styles.isReadButton}
          onClick={handleMarkRead}
          aria-label={t(notification.read ? 'isRead' : 'markRead')}
          aria-describedby={headingId}
        >
          <span className="icon icon--mail" />
        </button>
      </div>
    </div>
  );
};

Notification.propTypes = {
  notification: notificationType.isRequired,
  markRead: func.isRequired,
  isFocus: bool.isRequired,
  lang: string
};

export default Notification;
